import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { User } from '@/types/User';
import type { TableProps } from 'antd';
import { Pagination, Table } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { useCallback, useEffect, useState } from 'react';
import { DataType, MetaType, QueryUserPagination, columns } from './constant';
import { toast } from 'react-toastify';
import { PageSize } from '@/constants/Common';
import { getCustomerRankName, getCustomerRole } from '@/utils/formatUser';

type Props = {
    valueSearch: string;
};

export default function AllUser({ valueSearch }: Props) {
    const [listUser, setListUser] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMeta, setIsMeta] = useState<MetaType | null>(null);

    const [query, setQuery] = useState<QueryUserPagination>({
        query: valueSearch,
        limit: PageSize.medium,
        page: 1,
    });

    const getAllUser = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await http.get(ENDPOINTCONSTANTS.USER, { params: query });

            console.log('response', response.data);

            setIsMeta({
                page: response.data.page,
                size: response.data.size,
                totalPage: response.data.totalPage,
            });

            setListUser(response.data.users);
        } catch (error: any) {
            const getAllUser = async () => {
                setIsLoading(true);
                try {
                    const response = await http.get(ENDPOINTCONSTANTS.USER, {
                        params: query,
                    });

                    setListUser(response.data.users);
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setIsLoading(false);
                }
            };
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }, [query]);

    useEffect(() => {
        getAllUser();
    }, [getAllUser]);

    useEffect(() => {
        setQuery((prevQuery) => ({ ...prevQuery, query: valueSearch }));
    }, [valueSearch]);

    const dataSources: DataType[] = listUser.map((user) => ({
        key: user.id,
        id: user.id,
        name: `${user?.name}`,
        phone: user?.phoneNumber || '---',
        // address: `${
        //     user?.defaultAddress
        //         ? `${user.defaultAddress.city ? `${user.defaultAddress.city},` : ''} ${
        //               user.defaultAddress.country ? user.defaultAddress.country : ''
        //           }`
        //         : '---'
        // }`,
        kind: getCustomerRole(user?.role),
        rank: getCustomerRankName(user?.rank) || '---',
    }));

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataSources}
                loading={isLoading}
                pagination={{
                    current: Number(query.page),
                    pageSize: Number(query.limit),
                    total: (isMeta?.totalPage || 0) * Number(query.limit),
                    onChange: (page, pageSize) => {
                        setQuery((prevQuery) => ({ ...prevQuery, page: page, limit: pageSize }));
                    },
                }}
            />
        </>
    );
}
