import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { AuthAction } from '@/redux/reducers/auth/authReducer';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space, message } from 'antd';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
// const { Search } = Input;
// import React from 'react';

const items: MenuProps['items'] = [
    {
        key: '1',
        label: <>Đăng xuất</>,
    },
];

// const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);
function HeaderComponents() {
    const dispatch = useAppDispatch();
    const router = useNavigate();

    const onClick: MenuProps['onClick'] = ({ key }) => {
        dispatch(AuthAction.update({ accessToken: '', refreshToken: '', user: null }));
        router('/login');
    };
    const Account = useAppSelector((state) => state.root.auth.user);

    // minull1810@gmail => minull1810
    const formatMail = (email: string) => {
        return email.split('@')[0];
    };

    return (
        <header className='bg-white w-full'>
            <section className=" flex justify-end   py-[31.2px] px-5 items-center w-full">
                <Dropdown menu={{ items, onClick }}>
                    <div className="text-lg font-semibold">
                        <Space>
                            Hi, {formatMail(Account?.authId || 'admin')}
                            <DownOutlined />
                        </Space>
                    </div>
                </Dropdown>
            </section>
        </header>
    );
}

export default HeaderComponents;
