import WebImage from '@/assets/imgs';
import Logo from '@/components/customs/Logo';
import LoadAction from '@/components/shared/LoadAction';
import { auth } from '@/configs/firebase';
import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { useAppDispatch } from '@/hooks/useRedux';
import { AuthAction } from '@/redux/reducers/auth/authReducer';
import { Button, Form, Input } from 'antd';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginScreen: React.FC = () => {
    const [form] = Form.useForm();
    const router = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onFinish = async () => {
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                form.getFieldValue('email'),
                form.getFieldValue('password'),
            );

            const accessToken = await userCredential.user?.getIdToken();
            const { data } = await http.post(ENDPOINTCONSTANTS.OAUTH, {
                token: accessToken,
            });

            dispatch(AuthAction.update(data));
            toast.success('Đăng nhập thành công');
            router('/manager/user');
        } catch (error: any) {
            console.log(error);
            toast.error('Đăng nhập thất bại');
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className=" h-screen flex justify-center items-center">
            {loading && <LoadAction />}
            <div className="flex-1 hidden lg:block  h-full">
                <img src={WebImage.bgLogin} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="flex-1 bg-white flex justify-center items-center h-full">
                <div className="shadow-default  border px-8 w-full min-h-[50vh] justify-center max-w-[28rem] border-primary rounded-2xl py-8 flex flex-col items-center gap-8">
                    <div className="flex flex-col items-center gap-2">
                        <h2 className="uppercase text-primary font-bold text-4xl">JEMMIA Priority</h2>
                        <h2 className="text-sm">Xin chào, vui lòng nhập thông tin đăng nhập</h2>
                    </div>
                    <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish} className="w-full">
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Vui lòng nhập email!' },
                                { type: 'email', message: 'Email không hợp lệ!' },
                            ]}
                            className="w-full"
                        >
                            <Input placeholder="Email" className="w-full h-[2.75rem]" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
                            <Input type="password" placeholder="Password" className="h-[2.75rem]" />
                        </Form.Item>
                        <Form.Item shouldUpdate className="">
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="w-full py-5 mt-4 flex justify-center items-center bg-primary"
                                >
                                    Đăng nhập
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </main>
    );
};

export default LoginScreen;
