import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxStore } from '@/redux/types';
import { UserState } from './userType';

const initialState: UserState = {
    listUser: null,
};

const reducer = createSlice({
    name: ReduxStore.auth,
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<Partial<UserState>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const UserAction = reducer.actions;
export const userReducer = reducer.reducer;
