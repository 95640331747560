import { PATHCONSTANTS } from '@/constants/PathConstants';
import { Route, Routes } from 'react-router-dom';
import DefaultLayout from '../layouts/DefaultLayout';

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<DefaultLayout />}>
                <Route path={PATHCONSTANTS.DASHBOARD.PATH} element={PATHCONSTANTS.DASHBOARD.ELEMENT} />
                {/* User Manager */}
                <Route path={PATHCONSTANTS.USER_MANAGER.PATH} element={PATHCONSTANTS.USER_MANAGER.ELEMENT} />
                <Route path={PATHCONSTANTS.USER_MANAGER_NEW.PATH} element={PATHCONSTANTS.USER_MANAGER_NEW.ELEMENT} />
                <Route
                    path={PATHCONSTANTS.USER_MANAGER_DETAIL.PATH}
                    element={PATHCONSTANTS.USER_MANAGER_DETAIL.ELEMENT}
                />
                {/* Coupon Manager  */}
                <Route path={PATHCONSTANTS.COUPON_MANAGER.PATH} element={PATHCONSTANTS.COUPON_MANAGER.ELEMENT} />
                {/* Partner Invite Code Manager */}
                <Route
                    path={PATHCONSTANTS.PARTNER_INVITATION_CODE.PATH}
                    element={PATHCONSTANTS.PARTNER_INVITATION_CODE.ELEMENT}
                />
                {/* Exchange Gifts */}
                <Route path={PATHCONSTANTS.EXCHANGE_GIFTS.PATH} element={PATHCONSTANTS.EXCHANGE_GIFTS.ELEMENT} />
                {/* Product OSD */}
                {/* Product Outstading */}
                <Route path={PATHCONSTANTS.PRODUCT_OSD.PATH} element={PATHCONSTANTS.PRODUCT_OSD.ELEMENT} />
                <Route path={PATHCONSTANTS.PRODUCT_OSD_EDIT.PATH} element={PATHCONSTANTS.PRODUCT_OSD_EDIT.ELEMENT} />
                <Route path={PATHCONSTANTS.PRODUCT_OSD_NEW.PATH} element={PATHCONSTANTS.PRODUCT_OSD_NEW.ELEMENT} />
                {/* System Program */}
                <Route
                    path={PATHCONSTANTS.SYSTEM_PROGRAM_NEW.PATH}
                    element={PATHCONSTANTS.SYSTEM_PROGRAM_NEW.ELEMENT}
                />
                <Route
                    path={PATHCONSTANTS.SYSTEM_PROGRAM_BY_ID.PATH}
                    element={PATHCONSTANTS.SYSTEM_PROGRAM_BY_ID.ELEMENT}
                />
                <Route path={PATHCONSTANTS.SYSTEM_PROGRAM.PATH} element={PATHCONSTANTS.SYSTEM_PROGRAM.ELEMENT} />
                {/* Outstading Program */}
                <Route path={PATHCONSTANTS.OSD_PROGRAM.PATH} element={PATHCONSTANTS.OSD_PROGRAM.ELEMENT} />
                <Route path={PATHCONSTANTS.OSD_PROGRAM_EDIT.PATH} element={PATHCONSTANTS.OSD_PROGRAM_EDIT.ELEMENT} />
                <Route path={PATHCONSTANTS.OSD_PROGRAM_NEW.PATH} element={PATHCONSTANTS.OSD_PROGRAM_NEW.ELEMENT} />
                {/* News */}
                <Route path={PATHCONSTANTS.NEWS.PATH} element={PATHCONSTANTS.NEWS.ELEMENT} />
                <Route path={PATHCONSTANTS.NEWS_EDIT.PATH} element={PATHCONSTANTS.NEWS_EDIT.ELEMENT} />
                <Route path={PATHCONSTANTS.NEWS_NEW.PATH} element={PATHCONSTANTS.NEWS_NEW.ELEMENT} />
                {/* Withdraw Money */}
                <Route path={PATHCONSTANTS.WITHDRAW_MONEY.PATH} element={PATHCONSTANTS.WITHDRAW_MONEY.ELEMENT} />
                {/* SpecialOffers */}
                <Route path={PATHCONSTANTS.SPECIAL_OFFERS.PATH} element={PATHCONSTANTS.SPECIAL_OFFERS.ELEMENT} />
                <Route
                    path={PATHCONSTANTS.SPECIAL_OFFERS_NEW.PATH}
                    element={PATHCONSTANTS.SPECIAL_OFFERS_NEW.ELEMENT}
                />
                <Route
                    path={PATHCONSTANTS.SPECIAL_OFFERS_EDIT.PATH}
                    element={PATHCONSTANTS.SPECIAL_OFFERS_EDIT.ELEMENT}
                />
            </Route>
            {/* Login */}
            <Route path={PATHCONSTANTS.LOGIN.PATH} element={PATHCONSTANTS.LOGIN.ELEMENT} />

            {/* Not Found  */}
            <Route path={PATHCONSTANTS.NOT_FOUND.PATH} element={PATHCONSTANTS.NOT_FOUND.ELEMENT} />
        </Routes>
    );
};

export default AppRoutes;
