import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import React, { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import HeaderComponents from '../components/Header';
import MenuSide from '../components/Menu';
import { useAppSelector } from '@/hooks/useRedux';
import Loading from '@/components/shared/Loading';

const DefaultLayout: React.FC = () => {
    const accessToken = useAppSelector((state) => state.root.auth.accessToken);

    const router = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            router('/login');
        }
    }, [accessToken, router]);

    return (
        <Layout hasSider className="flex ">
            <Sider
                width={300}
                style={{
                    paddingTop: '30px',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    background: 'white',
                    position: 'sticky',
                    height: '100vh',
                }}
                breakpoint="xl"
                collapsedWidth="0"
                onBreakpoint={(broken) => {}}
            >
                <div className="demo-logo-vertical" />
                <MenuSide />
            </Sider>
            <Layout className="mx-0 w-[65%]">
                <Header
                    className="backdrop-blur-lg z-40 "
                    style={{
                        padding: 0,
                        // background: 'white',
                        boxShadow: '20px',
                        position: 'sticky',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        backdropFilter: '',
                    }}
                >
                    <HeaderComponents />
                </Header>

                <Content style={{ margin: '50px 16px 0', overflow: 'initial' }}>
                    <div
                        style={{
                            // minHeight: '100vh',
                            background: 'transparent',
                            borderRadius: '10px',
                        }}
                        className="pb-[2rem]"
                    >
                        <Suspense fallback={<Loading />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default DefaultLayout;
