import { Table, TableColumnsType } from 'antd';

interface DataType {
    name: string;
    image: string;
    NumberOfPointsToBeExchanged: number;
    NumberOfTimesChanged: number;
    startDay: string;
    endDay: string;
}

const data: DataType[] = [
    {
        name: 'Sp-001',
        image: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/937e630c-0a71-49cb-bdb7-0951432b6993/decjkyq-03baf551-ef29-44d5-b6ce-56a4953652a3.jpg/v1/fit/w_828,h_1176,q_70,strp/877_by_superschool48_decjkyq-414w-2x.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTgxNyIsInBhdGgiOiJcL2ZcLzkzN2U2MzBjLTBhNzEtNDljYi1iZGI3LTA5NTE0MzJiNjk5M1wvZGVjamt5cS0wM2JhZjU1MS1lZjI5LTQ0ZDUtYjZjZS01NmE0OTUzNjUyYTMuanBnIiwid2lkdGgiOiI8PTEyODAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.-8jF3-BD7kpDWFaUlMHwvfaTPO9_V3K9PPlL-JLmeYc',
        NumberOfPointsToBeExchanged: 100,
        NumberOfTimesChanged: 4,
        startDay: '21/09/2021 - 12:00',
        endDay: '21/09/2021 - 12:00',
    },
];

export default function ListExchangeGifs() {
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Tên phần quà',
            dataIndex: 'name',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: 'Ảnh',
            dataIndex: 'image',
            render: (id: string) => <img src={id} alt="ảnh" className="w-[4rem] h-[4rem] object-cover rounded-lg" />,
        },
        {
            title: 'Số điểm cần đổi',
            dataIndex: 'NumberOfPointsToBeExchanged',
        },
        {
            title: 'Số lần đã đổi',
            dataIndex: 'NumberOfTimesChanged',
        },
        {
            title: 'Ngày bắt đầu',
            dataIndex: 'startDay',
        },
        {
            title: 'Ngày kết thúc',
            dataIndex: 'endDay',
        },
    ];

    return (
        <>
            <Table columns={columns} dataSource={data} />
        </>
    );
}
