import { PATHCONSTANTS } from '@/constants/PathConstants';
import { EllipsisOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Space, Tabs, TabsProps } from 'antd';
import { Link } from 'react-router-dom';
import { items } from './constant';
import AllUser from './components/AllUser';
import ReceivePromotionalEmails from './components/ReceivePromotionalEmails';
import LoyalCustomers from './components/LoyalCustomers';
import PotentialCustomers from './components/PotentialCustomers';
import { useState } from 'react';

const { Search } = Input;

function UserManager() {
    const [isSearch, setIsSearch] = useState('' as string);

    const itemsTabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Tất cả khách hàng',
            children: <AllUser valueSearch={isSearch} />,
        },
        {
            key: '2',
            label: 'Đối tác khách hàng hạng A',
            children: <ReceivePromotionalEmails />,
        },
        {
            key: '3',
            label: 'Đối tác khách hàng hạng B',
            children: <LoyalCustomers />,
        },
        {
            key: '4',
            label: 'Khách hàng thông thường',
            children: <PotentialCustomers />,
        },
    ];

    const onChange = (key: string) => {};

    const handleSearch = (value: string) => {
        setIsSearch(value);
    };

    return (
        <main className="w-full flex flex-col gap-5">
            <section className="bg-white rounded-lg flex items-center justify-between py-5 px-5">
                <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                    <p>{PATHCONSTANTS.USER_MANAGER.TITLE}</p>
                </div>
                <div className="text-lg flex gap-5 duration-300">
                    {/* <Dropdown menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className="bg-gray-200 flex justify-center items-center h-[2.4rem] w-[2.4rem] rounded-lg">
                                <EllipsisOutlined
                                    style={{ fontSize: '1.5rem' }}
                                    className="translate-y-[0.15rem] text-gray-800"
                                />
                            </Space>
                        </a>
                    </Dropdown> */}
                    {/* <Link to={PATHCONSTANTS.USER_MANAGER_NEW.PATH}>
                        <Button type="primary" className="bg-primary h-[2.4rem] flex items-center">
                            <PlusCircleOutlined />
                            {PATHCONSTANTS.USER_MANAGER_NEW.TITLE}
                        </Button>
                    </Link> */}
                </div>
            </section>
            <section className="bg-white rounded-lg py-5 px-5">
                <div className="w-full flex items-center gap-5 mb-5">
                    <Search
                        placeholder="Tìm kiếm"
                        enterButton="Tìm kiếm"
                        className="h-[2.75rem] w-full"
                        size="middle"
                        onSearch={handleSearch}
                    />
                    {/* <Dropdown menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className="bg-gray-200 flex justify-center items-center h-[2.75rem] w-[2.75rem] rounded-lg">
                                <EllipsisOutlined
                                    style={{ fontSize: '1.5rem' }}
                                    className="translate-y-[0.15rem] text-gray-800"
                                />
                            </Space>
                        </a>
                    </Dropdown> */}
                </div>
                {/* <Tabs defaultActiveKey="1" items={itemsTabs} onChange={onChange} className="mt-5" /> */}
                <AllUser valueSearch={isSearch} />
            </section>
        </main>
    );
}

export default UserManager;
