import AllCoupon from './components/AllCoupon';

export default function CouponManager() {
    return (
        <div className="flex flex-col gap-5">
            <div className="w-full flex justify-between h-[3rem] items-center">
                <h1 className="text-xl">Quản lý mã mời</h1>
            </div>
            <div className="rounded-xl p-5 shadow-default bg-white w-full ">
                <AllCoupon />
            </div>
        </div>
    );
}
