import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useLocation } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';

export function GetParams() {}

const funcUtils = {
    // getParams: () =>{
    //     return new URLSearchParams(location.search).get('param');
    // },

    GetParam: () => {
        const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get('param');

        return paramValue;
    },
    notify: (content: string, type: 'error' | 'warning' | 'success' | 'loading') => {
        const option: ToastOptions = {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        };
        if (!content) return console.error('Có lỗi vui lòng liên hệ quản trị viên!', option);

        switch (type) {
            case 'error':
                return toast.error(content, option);

            case 'warning':
                return toast.warn(content, option);

            case 'success':
                return toast.success(content, option);
            case 'loading':
                return toast.loading(content, option);
            default:
                break;
        }
    },

    //   fetchHeader: () =>{
    //     const token = useAppSelector((state) => state.root.auth.accessToken)
    //     const config =   {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     }

    //     return config
    //   }
};

export default funcUtils;
