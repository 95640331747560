export const ENDPOINTCONSTANTS = {
    OAUTH: '/auth/oauth',
    USER: '/user',

    REPORT: '/customer-rank/report',

    BLOGNEWS: '/blog?blogId=1000909551',
    BLOGENDOW: '/blog?blogId=1000909552',
    BLOGOSPRODUCT: '/blog?blogId=1000909553',
    BLOGOSPROGRAM: '/blog?blogId=1000909554',

    COUNTRIES: '/information/countries',
    BLOG: '/blog',

    COUPON: '/coupon',

    WITHDRAW_MONEY: '/withdraw',
    COUPON_REF: '/coupon-ref',
};

export const BlogType = {
    TIN_SU_KIEN: 1000903697,
    BLOGNEWS: 1000909551,
    BLOGENDOW: 1000909552,
    BLOGOSPRODUCT: 1000909553,
    BLOGOSPROGRAM: 1000909554,
};

export const Category = [
    {
        label: 'Sản phẩm nổi bật',
    },
    {
        label: 'Hệ thống',
    },
    {
        label: 'Tin tức',
    },
];
