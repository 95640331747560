import funcUtils from '@/configs/funcUtil';
import http from '@/configs/http';
import { BlogType, ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { PATHCONSTANTS } from '@/constants/PathConstants';
import { INews } from '@/types/News';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Table, TableColumnsType, TableProps } from 'antd';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;
type OnChange = NonNullable<TableProps<INews>['onChange']>;
type Filters = Parameters<OnChange>[1];
function TableSystem() {
    const [filteredInfo, setFilteredInfo] = useState<Filters>({});
    const [sortedInfo, setSortedInfo] = useState<Sorts>({});
    const handleChange: OnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter as Sorts);
    };
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<INews[]>([]);

      // SEARCH
      const [filteredData, setFilteredData] = useState<INews[]>([]);
      const handleSearch = (value: string) => {
          setLoading(true);
          if (data) {
              const result = data.filter((item: any) => item?.title.includes(value));
              setFilteredData(result);
              setLoading(false);
          }
      };
    // SELECTED ROW
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const fechData = async () => {
        setLoading(true);
        const res = await http.get(`${ENDPOINTCONSTANTS.BLOGENDOW}`);
        if (res.status === 200) {
            setLoading(false);
            setData(res?.data);
        }
    };
    useEffect(() => {
        fechData();
    }, []);
    // delete
    const onDelete = async (id: number) => {
        try {
            const rep = await http.delete(`${ENDPOINTCONSTANTS.BLOG}/${id}?blogType=${BlogType.BLOGENDOW}`);
            if (rep?.status === 200) {
                funcUtils.notify('Xóa Thành Công ', 'success');
                fechData();
            }
        }catch (error) {
            funcUtils.notify('Xóa thất bại', 'error');
        }
       
    };
    const columns: TableColumnsType<INews> = [
        {
            title: '',
            dataIndex: 'image',
            key: 'id',
            render: (avata, e: INews) => (
                <div className="flex gap-3 items-center">
                    <img src={e.image?.src as string} alt="avatar" style={{ width: '50px', height: '50px' }} />
                </div>
            ),
        },
        {
            title: 'Tên Hệ Thống',
            dataIndex: 'title',
            key: 'title',
        },
        // {
        //     title: 'Danh Mục',
        //     dataIndex: 'category',
        //     key: 'category',
        //     render: (text: string, e: INews) => (
        //         <Link to={`news?param=${e.blogType}`}>
        //             {e.blogType === BlogType.TIN_SU_KIEN ? 'Tin Sự Kiện' : 'Tin Khác'}
        //         </Link>
        //     ),
        //     // onCellClick: (e:DataTableType) => <Link to={`${e.key}`}></Link>
        // },
        {
            title: 'Ngày Đăng',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => moment(date).format('DD/MM/YYYY'),
        },
        // {
        //     title: 'Người Tạo',
        //     dataIndex: 'createBy',
        //     key: 'createBy',
        //     render: (text: string, e: INews) => <Link to={`${e.id}?param=${e.blogType}`}>{e.author}</Link>,
        //     // onCellClick: (e:DataTableType) => <Link to={`${e.key}`}></Link>
        // },
        // {
        //     title: 'Trạng Thái',
        //     dataIndex: 'published',
        //     key: 'published',
        //     filters: [
        //         { text: 'Ẩn', value: false },
        //         { text: 'Hiện', value: true },
        //     ],
        //     filteredValue: filteredInfo.published || null,
        //     onFilter: (value: boolean | any, record: INews) => record.published === value,
        //     sorter: (a: INews, b: INews) => Number(a.published) - Number(b.published),
        //     sortOrder: sortedInfo.columnKey === 'published' ? sortedInfo.order : null,
        //     ellipsis: true,
        //     render: (published: boolean, e: INews, i) => (
        //         <p key={e.id} className={`${published === true ? 'text-primary' : 'text-red-400'}`}>
        //             {published === true ? 'Hiện' : 'Ẩn'}
        //         </p>
        //     ),
        // },
        {
            title: 'Thao Tác',
            dataIndex: 'id',
            key: 'id',
            render: (value, record, index) => {
                return (
                    <div className="flex gap-3 text-xl">
                        <Link to={`${PATHCONSTANTS.SYSTEM_PROGRAM_BY_ID.PATH.replace(':id', value)}`}>
                            <EditOutlined />
                        </Link>

                        <Popconfirm
                            title="Xóa hệ thống"
                            description={`Bạn có chắc xóa hệ thống có mã ${value}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => {
                                onDelete(value);
                            }}
                        >
                            <DeleteOutlined className="text-red-500" />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    return (
        <div>
             {/* <div className="w-full flex items-center gap-5">
                <Search
                    placeholder="Tìm kiếm"
                    enterButton="Tìm kiếm"
                    className="h-[2.75rem] w-full"
                    size="middle"
                    onSearch={handleSearch}
                />
            </div> */}
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={filteredData.length > 0 ? filteredData : data}
                onChange={handleChange}
            />
        </div>
    );
}

export default TableSystem;
