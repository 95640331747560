import InputCore from '@/components/customs/Input';
import { DataTableType } from '@/types/Table';
import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHCONSTANTS } from '@/constants/PathConstants';
import TableProduct from '@/components/customs/Table/TableProduct';
import { PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';

function ListProductOSD() {
    return (
        <main className="w-full flex flex-col gap-5">
            <section className="bg-white rounded-lg flex items-center justify-between py-5 px-5">
                <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                    <p>{PATHCONSTANTS.PRODUCT_OSD.TITLE}</p>
                </div>
                <div className="text-lg flex gap-5 duration-300 items-center">
                    {/* <Select
                        defaultValue="true"
                        style={{ width: 120 }}
                        onChange={handleSort}
                        options={[
                            { value: 'true', label: 'On' },
                            { value: 'false', label: 'Off' },
                        ]}
                    /> */}
                    {/* <Dropdown  trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className="bg-gray-200 flex justify-center items-center h-[2.4rem] w-[2.4rem] rounded-lg">
                                <EllipsisOutlined
                                    style={{ fontSize: '1.5rem' }}
                                    className="translate-y-[0.15rem] text-gray-800"
                                />
                            </Space>
                        </a>
                    </Dropdown> */}
                    {/* <Link to={PATHCONSTANTS.PRODUCT_OSD_NEW.PATH}>
                        <Button type="primary" className="bg-primary h-[2.4rem] flex items-center">
                            <PlusCircleOutlined />
                            {PATHCONSTANTS.PRODUCT_OSD.TITLE}
                        </Button>
                    </Link> */}
                </div>
            </section>
            <section className="bg-white rounded-lg  px-5 py-5">
                <TableProduct />
            </section>
        </main>
    );
}

export default ListProductOSD;
