import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthType } from './authType';
import { ReduxStore } from '@/redux/types';

const initialState: AuthType = {
    accessToken: '',
    refreshToken: '',
    user: null,
};

const reducer = createSlice({
    name: ReduxStore.auth,
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<Partial<AuthType>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const AuthAction = reducer.actions;
export const authReducer = reducer.reducer;
