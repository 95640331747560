import http from '@/configs/http';
import { ItemCoupon } from '@/types/Coupon';
import { formatDate } from '@/utils/formatDate';
import { Button, Modal } from 'antd';
import React from 'react';
import { toast } from 'react-toastify';

type ItemInvitecodeProps = {
    getListCoupon?: () => void;
} & ItemCoupon;

export default function ItemInvitecode(props: ItemInvitecodeProps) {
    const [isDelete, setIsDelete] = React.useState(false);
    const [isShowNote, setIsShowNote] = React.useState<string | null>(null);

    const handleDelete = async () => {
        try {
            await http.delete(`/coupon-ref/${props?.id}`);

            toast.success('Xóa mã mời thành công');
            props?.getListCoupon && props.getListCoupon();
        } catch (error) {
            console.log(error);
            toast.error('Xóa mã mời thất bại');
        } finally {
            setIsDelete(false);
        }
    };

    return (
        <div className="bg-white border flex items-center border-gray-200 rounded-lg p-5 ">
            <Modal title="Xóa mã mời" open={isDelete} onOk={handleDelete} onCancel={() => setIsDelete(false)}>
                <p className="text-red-500 text-lg font-bold">Bạn có chắc chắn muốn xóa không?</p>
            </Modal>
            <p className="flex-1 font-bold uppercase">#{props?.couponHaravanCode}</p>
            <p className="flex-1 font-bold">
                Người sử dụng: <span className="font-medium">{props?.usedBy?.name || '---'}</span>
            </p>
            <p className="flex-1">
                {props?.used ? (
                    <span className="py-2 px-3 rounded-full bg-green-500 text-xs font-semibold text-white ">
                        Đã sử dụng
                    </span>
                ) : (
                    <span className="py-2 px-3 rounded-full bg-red-500 text-xs font-semibold text-white ">
                        Chưa sử dụng
                    </span>
                )}
            </p>
            <p>{formatDate(props?.createdDate)}</p>
            <div className="flex-1 flex justify-end gap-4">
                {props?.note && (
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsShowNote(props?.note);
                            console.log(props?.note);
                        }}
                    >
                        Ghi chú
                    </Button>
                )}
                <Button onClick={() => setIsDelete(!isDelete)}>Xóa</Button>
            </div>
            <Modal
                title="Ghi chú"
                open={isShowNote ? true : false}
                onCancel={() => setIsShowNote(null)}
                onOk={() => setIsShowNote(null)}
                footer={null}
            >
                <p className="font-medium  py-4 text-base">{isShowNote}</p>
            </Modal>
        </div>
    );
}
