import React, { Suspense } from 'react';
import Loading from './components/shared/Loading';
import AppRoutes from './routers';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <Suspense fallback={<Loading />}>
                <AppRoutes />
                <ToastContainer />
            </Suspense>
        </Provider>
    );
};

export default App;
