// 2024-03-27T20:24:37.000Z -> 27/3/2024 12:00:00 AM

export const formatTime = (date: string) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    // AM/PM
    const ampm = hour >= 12 ? 'PM' : 'AM';
    // 12h format
    const hour12 = hour % 12 || 12;
    return `${day}/${month}/${year} ${hour12}:${minute} ${ampm}`;
};

export const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
};
