import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { Button, InputNumber, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { toast } from 'react-toastify';

type CreateCouponRefProps = {
    setLoading: (value: boolean) => void;
    refetchData: () => void;
};

export default function CreateCouponRef({ setLoading, refetchData }: CreateCouponRefProps) {
    const [isCreateCouponRef, setIsCreateCouponRef] = useState<boolean>(false);
    const [isAmount, setIsAmount] = useState<number>(1);
    const [isNote, setIsNote] = useState<string>('');

    const handleCreateCouponRef = async () => {
        setLoading(true);
        try {
            setIsCreateCouponRef(false);

            const payload = {
                type: 'partner',
                role: 'partnerA',
                note: isNote,
            };

            for (let i = 0; i < isAmount; i++) {
                await http.post(ENDPOINTCONSTANTS.COUPON_REF, payload);
            }
        } catch (error) {
            console.log('error', error);
            toast.error('Tạo mã mời thất bại');
        } finally {
            setLoading(false);
            refetchData();
            toast.success('Tạo mã mời thành công');
            setIsAmount(1);
            setIsNote('');
        }
    };

    return (
        <>
            <Modal
                title="Tạo mã mời"
                visible={isCreateCouponRef}
                onOk={() => {
                    handleCreateCouponRef();
                }}
                onCancel={() => setIsCreateCouponRef(false)}
                okText="Tạo"
                cancelText="Hủy"
            >
                <div className="w-full flex flex-col gap-5 my-5">
                    <InputNumber
                        placeholder="Nhập số lượng mã mời"
                        className="w-full h-[2.75rem] items-center flex"
                        value={isAmount}
                        onChange={(value) => setIsAmount(value as number)}
                        min={1}
                    />
                    <TextArea
                        placeholder="Nhập ghi chú"
                        className="w-full"
                        value={isNote}
                        onChange={(e) => setIsNote(e.target.value)}
                    />
                </div>
            </Modal>
            <Button
                type="primary"
                className="h-[2.6rem] font-bold"
                onClick={() => {
                    setIsCreateCouponRef(true);
                }}
            >
                Tạo mã mời
            </Button>
        </>
    );
}
