import { BaseQueryPagination } from '@/utils';
import { Button, TableProps, Typography } from 'antd';

export interface MetaType {
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    totalItems: number;
    currentPage: number;
}

export interface DataType {
    key: string;
    inviteCode: string;
    timeStart: string;
    timeEnd: string;
    used: boolean;
    usedBy: string;
    note: string;
    action: string;
}

const { Paragraph } = Typography;

export interface QueryUserPagination extends BaseQueryPagination {
    role: string;
    type: string;
}

export const columnsPartnerInvitationCode: TableProps<DataType>['columns'] = [
    {
        title: 'STT',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => index + 1,
    },
    {
        title: 'Mã mời',
        dataIndex: 'inviteCode',
        key: 'inviteCode',
        render: (text) => <Paragraph copyable>{text.toLocaleUpperCase()}</Paragraph>,
    },
    {
        title: 'Thời gian bắt đầu',
        dataIndex: 'timeStart',
        key: 'timeStart',
    },
    {
        title: 'Thời gian kết thúc',
        dataIndex: 'timeEnd',
        key: 'timeEnd',
    },
    {
        title: 'Đã sử dụng',
        dataIndex: 'used',
        key: 'used',
        render: (text) => (text ? 'Đã sử dụng' : 'Chưa sử dụng'),
    },
    {
        title: 'Người sử dụng',
        dataIndex: 'usedByName',
        key: 'usedByName',
    },
    {
        title: 'Ghi chú',
        dataIndex: 'note',
        key: 'note',
    },
    {
        title: 'Hành động',
        dataIndex: 'action',
        key: 'action',
    },
];
