import LoginScreen from '@/screens/Auth/Login';
import NotFoundScreen from '@/screens/NotFound';
import ListProductOSD from '@/screens/Manager/Blog/ProductOSD';
import DashboardScreen from '@/screens/Dashboard';
import UserManager from '@/screens/Manager/User';
import OSD_Program from '@/screens/OSD_Program';
import ListSystem from '@/screens/Manager/Blog/SystemManager';
import ListNews from '@/screens/Manager/Blog/News';
import EditProducts from '@/components/shared/EditProducts';
import FormNews from '@/components/shared/FormEdit/FormNews';
import UserNew from '@/screens/Manager/User/UserNew';
import CouponManager from '@/screens/Manager/Coupon';
import DetailUser from '@/screens/Manager/User/UserDetail';
import WithdrawMoney from '@/screens/Manager/WithdrawMoney';
import SpecialOffers from '@/screens/Manager/Blog/SpecialOffers';
import Newsnew from '@/screens/Manager/Blog/News/Newsnew';
import UpdateNew from '@/screens/Manager/Blog/News/UpdateNews';
import ProductNew from '@/screens/Manager/Blog/ProductOSD/ProductNew';
import SMNEW from '@/screens/Manager/Blog/SystemManager/SMNew';
import SONew from '@/screens/Manager/Blog/SpecialOffers/SONew';
import ProducUpdate from '@/screens/Manager/Blog/ProductOSD/ProductUpdate';
import SMUpdate from '@/screens/Manager/Blog/SystemManager/SMUpdate';
import SOUpdate from '@/screens/Manager/Blog/SpecialOffers/SOUpdate';
import ExchangeGifts from '@/screens/Manager/ExchangeGifts';
import PartnerInvitationCode from '@/screens/Manager/PartnerInvitationCode';

export const PATHCONSTANTS = {
    // Auth
    LOGIN: {
        PATH: '/login',
        TITLE: 'Login',
        ELEMENT: <LoginScreen />,
    },
    // Manager
    DASHBOARD: {
        PATH: '/',
        TITLE: 'Thống kê',
        ELEMENT: <DashboardScreen />,
    },
    // User Manager
    USER_MANAGER: {
        PATH: '/manager/user',
        TITLE: 'Quản lý khách hàng',
        ELEMENT: <UserManager />,
    },
    USER_MANAGER_NEW: {
        PATH: '/manager/user/new',
        TITLE: 'Thêm khách hàng',
        ELEMENT: <UserNew />,
    },
    USER_MANAGER_DETAIL: {
        PATH: '/manager/user/:id',
        TITLE: 'Thông tin khách hàng',
        ELEMENT: <DetailUser />,
    },
    // Manager Partner Invitation Code
    PARTNER_INVITATION_CODE: {
        PATH: '/manager/partner-invitation-code',
        TITLE: 'Mã mời đối tác',
        ELEMENT: <PartnerInvitationCode />,
    },

    // Manager coupon
    COUPON_MANAGER: {
        PATH: '/manager/coupon',
        TITLE: 'Quản lý mã mời',
        ELEMENT: <CouponManager />,
    },
    // Manager Exchange Gifts
    EXCHANGE_GIFTS: {
        PATH: '/manager/exchange-gifts',
        TITLE: 'Quản lý đổi quà',
        ELEMENT: <ExchangeGifts />,
    },
    // Program Outstanding
    OSD_PROGRAM: {
        PATH: '/manager/osd-program',
        TITLE: 'Quản lý kiến thức kim cương',
        ELEMENT: <OSD_Program />,
    },
    OSD_PROGRAM_EDIT: {
        PATH: '/manager/osd-program/:id',
        TITLE: 'Chi tiết kiến thức kim cương',
        ELEMENT: <OSD_Program />,
    },
    OSD_PROGRAM_NEW: {
        PATH: '/manager/osd-program/new',
        TITLE: 'Thêm kiến thức kim cương',
        ELEMENT: <OSD_Program />,
    },
    // System Program
    SYSTEM_PROGRAM: {
        PATH: '/system-program',
        TITLE: 'Kiến thức kim cương',
        ELEMENT: <ListSystem />,
    },
    SYSTEM_PROGRAM_BY_ID: {
        PATH: '/system-program/:id',
        TITLE: 'Chỉnh Sửa kiến thức',
        ELEMENT: <SMUpdate />,
    },
    SYSTEM_PROGRAM_NEW: {
        PATH: '/system-program/new',
        TITLE: 'Tạo kiến thức kim cương Mới',
        ELEMENT: <SMNEW />,
    },
    // Product Outstanding
    PRODUCT_OSD: {
        PATH: '/product-osd',
        TITLE: 'Bài Viết Hệ Thống',
        ELEMENT: <ListProductOSD />,
        PARAM: 'product',
    },
    PRODUCT_OSD_NEW: {
        PATH: '/product-osd/new',
        TITLE: 'Thêm Bài Viết',
        ELEMENT: <ProductNew />,
    },
    PRODUCT_OSD_EDIT: {
        PATH: '/product-osd/:id',
        TITLE: 'Chỉnh sửa bài viết',
        ELEMENT: <ProducUpdate />,
        PARAMS: 'product',
    },
    // News
    NEWS: {
        PATH: '/news',
        TITLE: 'Tin Tức',
        ELEMENT: <ListNews />,
        PARAM: 'news',
    },
    NEWS_NEW: {
        PATH: '/news/new',
        TITLE: 'Tạo tin mới',
        ELEMENT: <Newsnew />,
        PARAM: 'news',
    },
    NEWS_EDIT: {
        PATH: '/news/:id',
        TITLE: 'Chỉnh Sửa Tin tức',
        ELEMENT: <UpdateNew />,
        PARAM: 'news',
    },
    NOT_FOUND: {
        PATH: '*',
        TITLE: 'Not Found',
        ELEMENT: <NotFoundScreen />,
    },
    // WithdrawMoney
    WITHDRAW_MONEY: {
        PATH: '/withdraw-money',
        TITLE: 'Liên Kết Rút tiền',
        ELEMENT: <WithdrawMoney />,
        PARAM: 'withdraw-money',
    },
    // Special offers
    SPECIAL_OFFERS: {
        PATH: '/special-offers',
        TITLE: 'Chương trình ưu đãi',
        ELEMENT: <SpecialOffers />,
        PARAM: 'specialOffers',
    },
    SPECIAL_OFFERS_NEW: {
        PATH: '/special-offers/special-offers-new',
        TITLE: 'Thêm Ưu Đãi',
        ELEMENT: <SONew />,
        PARAM: 'specialOffers',
    },
    SPECIAL_OFFERS_EDIT: {
        PATH: '/special-offers/:id',
        TITLE: 'Sửa Ưu Đãi',
        ELEMENT: <SOUpdate />,
        PARAM: 'specialOffers',
    },
};
