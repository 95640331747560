import Loading from '@/components/shared/Loading';
import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { ReportType } from '@/types/Report';
import { formatMoney } from '@/utils';
import { useEffect, useState } from 'react';

export default function DashboardScreen() {
    const [isLoading, setIsLoading] = useState(false);
    const [isData, setIsData] = useState<ReportType>();

    const handleGetReport = async () => {
        setIsLoading(true);
        try {
            const res = await http.get(ENDPOINTCONSTANTS.REPORT);

            console.log(res.data);
            setIsData(res.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetReport();
    }, []);

    if (isLoading) return <Loading />;

    return (
        <section className="flex flex-col  items-start justify-between px-5 gap-5">
            <h1 className="text-2xl font-bold">Thống kê</h1>
            <div className="w-full flex flex-col gap-5">
                <div className="w-full grid grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-5">
                    <div className="flex-1 bg-white rounded-xl p-4 flex gap-4 flex-col items-start justify-start">
                        <p className="py-1 px-3 rounded-lg bg-black  font-semibold text-white">Partner A</p>
                        <div className="w-full flex flex-col gap-4">
                            {/* <div className="flex items-center gap-4">
                                <h1 className="text-2xl font-bold">
                                    {isData?.partnerA.revenue ? formatMoney(isData?.partnerA.revenue) : 0}
                                </h1>
                                <span className="py-1 px-3 rounded-full bg-black text-white font-semibold text-xs">
                                    Doanh thu
                                </span>
                            </div>
                            <hr /> */}
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh thu:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerA.revenue ? formatMoney(isData?.partnerA.revenue) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Giá trị đơn hàng tích luỹ:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerA.totalPrice ? formatMoney(isData?.partnerA.totalPrice) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh số:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerA.sales ? formatMoney(isData?.partnerA.sales) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Người đã mời:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerA.referralCount ? isData?.partnerA.referralCount : 0}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 bg-white rounded-xl p-4 flex gap-4 flex-col items-start justify-start">
                        <p className="py-1 px-3 rounded-lg bg-black font-semibold text-white">Partner B</p>
                        <div className="w-full flex flex-col gap-4">
                            {/* <div className="flex items-center gap-4">
                                <h1 className="text-2xl font-bold">
                                    {isData?.partnerB.revenue ? formatMoney(isData?.partnerB.revenue) : 0}
                                </h1>
                                <span className="py-1 px-3 rounded-full bg-black text-white font-semibold text-xs">
                                    Doanh thu
                                </span>
                            </div>
                            <hr /> */}
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh thu:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerB.revenue ? formatMoney(isData?.partnerB.revenue) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Giá trị đơn hàng tích luỹ:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerB.totalPrice ? formatMoney(isData?.partnerB.totalPrice) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh số:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerB.sales ? formatMoney(isData?.partnerB.sales) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Người đã mời:</p>
                                    <p className="font-semibold">
                                        {isData?.partnerB.referralCount ? isData?.partnerB.referralCount : 0}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 bg-white rounded-xl p-4 flex gap-4 flex-col items-start justify-start">
                        <p className="py-1 px-3 rounded-lg bg-black font-semibold text-white">
                            Khách hàng thông thường
                        </p>
                        <div className="w-full flex flex-col gap-4">
                            {/* <div className="flex items-center gap-4">
                                <h1 className="text-2xl font-bold">
                                    {isData?.customer.revenue ? formatMoney(isData?.customer.revenue) : 0}
                                </h1>
                                <span className="py-1 px-3 rounded-full bg-black text-white font-semibold text-xs">
                                    Doanh thu
                                </span>
                            </div>
                            <hr /> */}
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh thu:</p>
                                    <p className="font-semibold">
                                        {isData?.customer.revenue ? formatMoney(isData?.customer.revenue) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Giá trị đơn hàng tích luỹ:</p>
                                    <p className="font-semibold">
                                        {isData?.customer.totalPrice ? formatMoney(isData?.customer.totalPrice) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh số:</p>
                                    <p className="font-semibold">
                                        {isData?.customer.sales ? formatMoney(isData?.customer.sales) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Người đã mời:</p>
                                    <p className="font-semibold">
                                        {isData?.customer.referralCount ? isData?.customer.referralCount : 0}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 bg-white rounded-xl p-4 flex gap-4 flex-col items-start justify-start">
                        <p className="py-1 px-3 rounded-lg bg-black font-semibold text-white">Nhân viên</p>
                        <div className="w-full flex flex-col gap-4">
                            {/* <div className="flex items-center gap-4">
                                <h1 className="text-2xl font-bold">
                                    {isData?.staff.revenue ? formatMoney(isData?.staff.revenue) : 0}
                                </h1>
                                <span className="py-1 px-3 rounded-full bg-black text-white font-semibold text-xs">
                                    Doanh thu
                                </span>
                            </div>
                            <hr /> */}
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh thu:</p>
                                    <p className="font-semibold">
                                        {isData?.staff.revenue ? formatMoney(isData?.staff.revenue) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Giá trị đơn hàng tích luỹ:</p>
                                    <p className="font-semibold">
                                        {isData?.staff.totalPrice ? formatMoney(isData?.staff.totalPrice) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Doanh số:</p>
                                    <p className="font-semibold">
                                        {isData?.staff.sales ? formatMoney(isData?.staff.sales) : 0}
                                    </p>
                                </div>
                                <div className="flex items-center w-full gap-4 p-2 rounded-lg bg-white border border-gray-200 justify-between">
                                    <p>Người đã mời:</p>
                                    <p className="font-semibold">
                                        {isData?.staff.referralCount ? isData?.staff.referralCount : 0}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
