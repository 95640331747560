// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from '@firebase/app-check';

// const firebaseConfig = {
//     apiKey: 'AIzaSyBFHViPP8vyWcapW_XNO_AhSmJOFC0aDsU',
//     authDomain: 'jemmia-priority.firebaseapp.com',
//     databaseURL: 'https://jemmia-priority-default-rtdb.asia-southeast1.firebasedatabase.app',
//     projectId: 'jemmia-priority',
//     storageBucket: 'jemmia-priority.appspot.com',
//     messagingSenderId: '303403978779',
//     appId: '1:303403978779:web:2e20f0fd5ccc52a25468be',
//     measurementId: 'G-M9Z0R1CG4P',
// };

const firebaseConfig = {
    apiKey: 'AIzaSyDv8kJOAxPU1uN7sSyQXt3NoltcqYEv1B0',
    authDomain: 'jemmia-priority-dev.firebaseapp.com',
    databaseURL: 'https://jemmia-priority-dev-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'jemmia-priority-dev',
    storageBucket: 'jemmia-priority-dev.appspot.com',
    messagingSenderId: '621988042390',
    appId: '1:621988042390:web:e9737a9f2690803b3054b7',
    measurementId: 'G-P8HWC62WFC',
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const db = getFirestore();
const storage = getStorage();

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6Lf-fsQpAAAAAAXNuexBgzR3vQOsy-1vCOy2OTsy'),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

export { auth, provider, db, storage };
