import { PATHCONSTANTS } from '@/constants/PathConstants';
import { UserOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import IonIcon from '@reacticons/ionicons';
import type { MenuProps } from 'antd';
import { Divider, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
// import { PATHCONSTANTS } from '@/routers';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items: MenuProps['items'] = [
    getItem(
        <Link to={PATHCONSTANTS.DASHBOARD.PATH}>{PATHCONSTANTS.DASHBOARD.TITLE}</Link>,
        'dashboard',
        <IonIcon name="bar-chart-outline" />,
    ),
    getItem('Quản Lý Bài Viết', 'sub3', null, [
        getItem(PATHCONSTANTS.SYSTEM_PROGRAM.TITLE, '2', <Link to={PATHCONSTANTS.SYSTEM_PROGRAM.PATH} />),
        getItem(
            PATHCONSTANTS.PRODUCT_OSD.TITLE,
            '4',
            <Link to={`${PATHCONSTANTS.PRODUCT_OSD.PATH}?param=${PATHCONSTANTS.NEWS.PARAM}`} />,
        ),

        getItem(
            PATHCONSTANTS.NEWS.TITLE,
            '8',
            <Link to={`${PATHCONSTANTS.NEWS.PATH}?param=${PATHCONSTANTS.NEWS.PARAM}`} />,
        ),
        getItem(
            PATHCONSTANTS.SPECIAL_OFFERS.TITLE,
            '10',
            <Link to={`${PATHCONSTANTS.SPECIAL_OFFERS.PATH}?param=${PATHCONSTANTS.SPECIAL_OFFERS.PARAM}`} />,
        ),
    ]),

    getItem(
        <Link to={PATHCONSTANTS.USER_MANAGER.PATH}>{PATHCONSTANTS.USER_MANAGER.TITLE}</Link>,
        'user-manager',
        <UserOutlined />,
    ),
    getItem(
        <Link to={PATHCONSTANTS.PARTNER_INVITATION_CODE.PATH}>{PATHCONSTANTS.PARTNER_INVITATION_CODE.TITLE}</Link>,
        'partner-invitation-code-manager',
        <UsergroupDeleteOutlined />,
    ),
    getItem(
        <Link to={PATHCONSTANTS.COUPON_MANAGER.PATH}>{PATHCONSTANTS.COUPON_MANAGER.TITLE}</Link>,
        'coupon-manager',
        <IonIcon name="ticket-outline" />,
    ),
    // getItem(user
    //     <Link to={PATHCONSTANTS.EXCHANGE_GIFTS.PATH}>{PATHCONSTANTS.EXCHANGE_GIFTS.TITLE}</Link>,
    //     'exchange-gifs-manager',
    //     <IonIcon name="gift-outline" />,
    // ),
    getItem(
        <Link to={PATHCONSTANTS.WITHDRAW_MONEY.PATH}>{PATHCONSTANTS.WITHDRAW_MONEY.TITLE}</Link>,
        'withdraw-money',
        <IonIcon name="card-outline" />,
    ),
];

const MenuSide: React.FC = () => {
    const onClick: MenuProps['onClick'] = (e) => {
        // console.log('click ', e);
    };

    return (
        <div>
            <p className="text-primary  text-center text-3xl font-semibold ">
                <Link to={'/manager/user'}>Jemmia Priority</Link>
            </p>
            <Divider className="bg-white" />
            <Menu
                onClick={onClick}
                style={{ width: 300, background: 'white', padding: '0 10px' }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                items={items}
            />
        </div>
    );
};

export default MenuSide;
