import IonIcon from '@reacticons/ionicons';
import { Button, Form, Input, Modal, Tabs, TabsProps } from 'antd';
import React, { useState } from 'react';
import ListExchangeGifs from './components/ListExchangeGifs';

const { Search } = Input;

const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'Tất cả khuyến mãi',
        children: <ListExchangeGifs />,
    },
    {
        key: '2',
        label: 'Đã kích hoạt',
        children: 'Content of Tab Pane 2',
    },
    {
        key: '3',
        label: 'Chưa kích hoạt',
        children: 'Content of Tab Pane 3',
    },
];

export default function ExchangeGifts() {
    const [open, setOpen] = useState(false);
    const onChange = (key: string) => {
        console.log(key);
    };

    return (
        <div className="flex flex-col gap-10">
            <div className="w-full flex justify-between ih-[3rem]tems-center">
                <h1 className="text-xl">Quản lý đổi quà</h1>
            </div>
            <div className="rounded-xl p-5 shadow-default bg-white w-full ">
                <div className="w-full flex gap-10 mb-5">
                    <Search
                        placeholder="Tìm kiếm"
                        enterButton="Tìm kiếm"
                        className="h-[2.4rem] flex-[1.5]"
                        size="middle"
                    />
                </div>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
            <Modal
                title="Tạo khuyến mãi"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={900}
            >
                jo
            </Modal>
        </div>
    );
}
