import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Table, TableColumnsType, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import http from '@/configs/http';
import { BlogType, ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import moment from 'moment';
import funcUtils from '@/configs/funcUtil';
import { PATHCONSTANTS } from '@/constants/PathConstants';
import { INews } from '@/types/News';
import Search from 'antd/es/input/Search';
type OnChange = NonNullable<TableProps<INews>['onChange']>;

type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const TableSpecial = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const [data, setData] = useState<INews[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const fetchAPI = async () => {
        setLoading(true);
        const res = await http.get(`${ENDPOINTCONSTANTS.BLOGOSPROGRAM}`);
        if (res.status === 200) {
            setData(res?.data);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAPI();
    }, []);
    const onDelete = async (id: number) => {
        try {
            const rep = await http.delete(`${ENDPOINTCONSTANTS.BLOG}/${id}?blogType=${BlogType.BLOGOSPROGRAM}`);
            if (rep?.status === 200) {
                funcUtils.notify('Xóa Thành Công ', 'success');
                fetchAPI();
            }
        } catch (error) {
            funcUtils.notify('Xóa thất bại', 'error');
        }
    };
    // SEARCH
    const [filteredData, setFilteredData] = useState<INews[]>([]);
    const handleSearch = (value: string) => {
        setLoading(true);
        if (data) {
            const result = data.filter((item: any) => item?.title.includes(value));
            setFilteredData(result);
            setLoading(false);
        }
    };
    // hide or show
    const [sortedInfo, setSortedInfo] = useState<Sorts>({});
    const [filteredInfo, setFilteredInfo] = useState<Filters>({});
    const handleChange: OnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter as Sorts);
    };
    const columnsProduct: TableColumnsType<INews> = [
        {
            title: '',
            dataIndex: 'image',
            key: 'id',
            render: (avata, e: INews) => (
                <div className="flex gap-3 items-center">
                    <img src={e.image?.src as string} alt="avatar" style={{ width: '50px', height: '50px' }} />
                </div>
            ),
        },
        {
            title: 'Tên CT Ưu đãi',
            dataIndex: 'title',
            key: 'title',
        },
        // {
        //     title: 'Danh Mục',
        //     dataIndex: 'category',
        //     key: 'category',
        //     // render: (text: string, e: INews) => <Link to={`${e.key}?param=${e.category}`}>{e.category}</Link>,
        //     // onCellClick: (e:INews) => <Link to={`${e.key}`}></Link>
        // },
        // {
        //     title: 'Giá',
        //     dataIndex: 'price',
        //     key: 'price',
        //     // render: (text: string, e: INews) => <Link to={`${e.key}?param=${e.category}`}>{e.price}VND</Link>,
        //     // onCellClick: (e:INews) => <Link to={`${e.key}`}></Link>
        // },
        // {
        //     title: 'Phầm trăm giảm',
        //     dataIndex: 'price',
        //     key: 'price',
        //     // render: (text: string, e: INews) => <Link to={`${e.key}?param=${e.category}`}>{e.price}VND</Link>,
        //     // onCellClick: (e:INews) => <Link to={`${e.key}`}></Link>
        // },
        {
            title: 'Ngày Đăng',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => moment(date).format('DD/MM/YYYY'),
        },
        // {
        //     title: '% Ưu Đãi',
        //     dataIndex: 'discountAmount',
        //     key: 'discountAmount',
        //     // render: (date: string) => moment(date).format('DD/MM/YYYY'),
        // },
        // {
        //     title: 'Loại khách hàng',
        //     dataIndex: 'userRole',
        //     key: 'userRole',
        //     render: (date: string) => moment(date).format('DD/MM/YYYY'),
        // },
        // {
        //     title: 'Trạng Thái',
        //     dataIndex: 'published',
        //     key: 'published',
        //     filters: [
        //         { text: 'Ẩn', value: false },
        //         { text: 'Hiện', value: true },
        //     ],
        //     filteredValue: filteredInfo.published || null,
        //     onFilter: (value: boolean | any, record: INews) => record.published === value,
        //     sorter: (a: INews, b: INews) => Number(a.published) - Number(b.published),
        //     sortOrder: sortedInfo.columnKey === 'published' ? sortedInfo.order : null,
        //     ellipsis: true,
        //     render: (published: boolean, e: INews, i) => (
        //         <p key={e.id} className={`${published === true ? 'text-primary' : 'text-red-400'}`}>
        //             {published === true ? 'Hiện' : 'Ẩn'}
        //         </p>
        //     ),
        // },
        {
            title: 'Thao Tác',
            dataIndex: 'id',
            key: 'id',
            render: (value, record, index) => {
                return (
                    <div className="flex gap-3 text-xl">
                        <Link to={`${PATHCONSTANTS.SPECIAL_OFFERS_EDIT.PATH.replace(':id', value)}`}>
                            <EditOutlined />
                        </Link>

                        <Popconfirm
                            title="Xóa hệ thống"
                            description={`Bạn có chắc xóa hệ thống có mã ${value}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => {
                                onDelete(value);
                            }}
                        >
                            <DeleteOutlined className="text-red-500" />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    return (
        <div>
            {/* <div className="w-full flex items-center gap-5">
                <Search
                    placeholder="Tìm kiếm"
                    enterButton="Tìm kiếm"
                    className="h-[2.75rem] w-full"
                    size="middle"
                    onSearch={handleSearch}
                />
            </div> */}
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={columnsProduct}
                dataSource={filteredData.length > 0 ? filteredData : data}
                onChange={handleChange}
            />
        </div>
    );
};

export default TableSpecial;
