import { BaseQueryPagination, formatMoney } from '@/utils';
import { TableColumnsType } from 'antd';
import { Link } from 'react-router-dom';

export interface QueryUserPagination extends BaseQueryPagination {}

export interface MetaType {
    page: string;
    size: string;
    totalPage: number;
}

export interface DataType {
    key: React.Key;
    id: string;
    name: string;
    phone: string;
    // address: string;
    kind: string;
    rank: string;
}

export const columns: TableColumnsType<DataType> = [
    {
        title: 'Khách hàng',
        dataIndex: 'name',
        render: (text, record) => <Link to={`/manager/user/${record.id}`}>{record.name}</Link>,
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'phone',
        render: (text, record) => <span>{record.phone}</span>,
    },
    // {
    //     title: 'Địa chỉ',
    //     dataIndex: 'address',
    //     render: (text, record) => <span>{record.address}</span>,
    // },
    {
        title: 'Loại khách hàng',
        dataIndex: 'kind',
        render: (text, record) => <span>{record.kind}</span>,
    },
    {
        title: 'Hạng khách hàng',
        dataIndex: 'rank',
        render: (text, record) => <span>{record.rank}</span>,
    },
];
