import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { Button, message, Popconfirm } from 'antd';
import { toast } from 'react-toastify';

type DeleteCouponRefProps = {
    id: string;
    setLoading: (value: boolean) => void;
    refetchData: () => void; // Thêm prop mới
};

export default function DeleteCouponRef({ id, setLoading, refetchData }: DeleteCouponRefProps) {
    const handleDeleteCouponRef = async () => {
        setLoading(true);
        try {
            // Thực hiện xóa mã mời ở đây
            const response = await http.delete(`${ENDPOINTCONSTANTS.COUPON_REF}/${id}`);

            toast.success('Xóa mã mời thành công');
        } catch (error) {
            console.log('error', error);
            toast.error('Xóa mã mời thất bại');
        } finally {
            setLoading(false);
            refetchData(); // Gọi hàm refetchData để fetch lại dữ liệu sau khi xóa mã mời
        }
    };

    return (
        <Popconfirm
            title="Xác nhận xóa mã mời?"
            description="Bạn có chắc chắn muốn xóa mã mời này?"
            onConfirm={handleDeleteCouponRef}
            onCancel={() => message.info('Đã hủy xóa mã mời')}
            okText="Có"
            cancelText="Không"
        >
            <Button danger>Xóa</Button>
        </Popconfirm>
    );
}
