import { store } from '@/redux/store';
import axios from 'axios';

// const API_URL = 'https://jemmia.lavenes.com';
// const API_URL = 'https://api.jemmia.lavenes.com';\
const API_URL = 'https://priority-api.jemmia.vn/';

// const API_URL = 'https://jemmia-production-server.vercel.app';

// const API_URL = 'https://jemmia-production-server-qrr2upxvj-laveness-projects.vercel.app';

const http = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' },
});

http.interceptors.request.use(
    function (config) {
        const accessToken = store.getState().root.auth.accessToken;
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

http.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default http;
