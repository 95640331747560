import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxStore } from '@/redux/types';
import { LocationState } from './locationType';

const initialState: LocationState = {
    country: null,
    district: null,
    province: null,
    ward: null,
};

const reducer = createSlice({
    name: ReduxStore.auth,
    initialState: initialState,
    reducers: {
        updateCountry: (state, action: PayloadAction<LocationState['country']>) => {
            state.country = action.payload;
        },
        updateProvince: (state, action: PayloadAction<LocationState['province']>) => {
            state.province = action.payload;
        },
        updateDistrict: (state, action: PayloadAction<LocationState['district']>) => {
            state.district = action.payload;
        },
        updateWard: (state, action: PayloadAction<LocationState['ward']>) => {
            state.ward = action.payload;
        },
    },
});

export const LocationAction = reducer.actions;
export const locationReducer = reducer.reducer;
