import { PATHCONSTANTS } from '@/constants/PathConstants';
import { CopyOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    Button,
    Dropdown,
    Input,
    MenuProps,
    Pagination,
    PaginationProps,
    Space,
    Table,
    TableColumnsType,
    Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ExportData from '../User/components/ExportData';
import InsertInformation from '../User/components/InsertInfomation';
import funcUtils from '@/configs/funcUtil';
import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { formatDate } from '@/utils/formatDate';
import { formatMoney } from '@/utils';
const { Search } = Input;
interface DataType {
    key: React.Key;
    id: string;
    bankName: string;
    bankNumber: string;
    amount: number;
    status: string;
    createdDate: string;
}

const WithdrawMoney = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DataType[]>([]);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(1);
    const getApi = async () => {
        setLoading(true);
        try {
            const response = await http.get(`${ENDPOINTCONSTANTS.WITHDRAW_MONEY}?page=${page}&size=${size}`);
            setTotal(response.data?.totalPage);
            setData(response.data?.requestWithdraws);
            if (response.status === 200) {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Lấy dữ liệu thất bại', 'error');
        }
    };
    const changeStatus = async (id: any, status: string) => {
        setLoading(true);
        try {
            const response = await http.put(`${ENDPOINTCONSTANTS.WITHDRAW_MONEY}`, {
                id: id,
                status: status,
            });
            if (response?.status === 200) {
                funcUtils.notify(`Bạn đã ${status === 'done' ? 'duyệt' : 'hủy'} thành công`, 'success');
                getApi();
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Thay đổi trạng thái thất bại', 'error');
        }
    };
    useEffect(() => {
        getApi();
    }, [page]);
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Tên ngân hàng',
            key: 'id',
            dataIndex: 'bankName',
        },
        {
            title: 'Số ngân hàng',
            key: 'id',
            dataIndex: 'bankNumber',
            render: (stk: number) => (
                <div className="flex gap-[10px]">
                    <div>{stk}</div>
                    <div>
                        {' '}
                        <CopyToClipboard text={stk.toString()}>
                            <CopyOutlined
                                onClick={() => {
                                    funcUtils.notify('Đã copy ', 'success');
                                }}
                                className="text-primary text-[16px] hover:scale-110 duration-200"
                            />
                        </CopyToClipboard>
                    </div>
                </div>
            ),
        },
        {
            title: 'Số tiền',
            key: 'id',
            dataIndex: 'amount',
            render: (amount: any) => {
                return formatMoney(amount);
            },
        },
        {
            title: 'Ngày tạo',
            key: 'id',
            dataIndex: 'createdDate',
            render: (date: any) => {
                return formatDate(date);
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'key',
            render: (status: string) => {
                return status === 'pending' ? (
                    <Tag color="gold">Chờ duyệt</Tag>
                ) : status === 'done' ? (
                    <Tag color="success">Đã duyệt</Tag>
                ) : (
                    <Tag color="red">Đã hủy</Tag>
                );
            },
        },
        {
            title: 'Thao tác',
            render: (data: any) => (
                <div>
                    {data?.status === 'pending' ? (
                        <div>
                            <Button
                                onClick={() => {
                                    changeStatus(data?.id, 'done');
                                }}
                                className="bg-primary text-white"
                            >
                                Duyệt
                            </Button>
                            <Button
                                onClick={() => {
                                    changeStatus(data?.id, 'rejected');
                                }}
                                className="bg-red-500 text-white"
                            >
                                Hủy
                            </Button>
                        </div>
                    ) : data?.status === 'done' ? (
                        <Button
                            onClick={() => {
                                changeStatus(data?.id, 'rejected');
                            }}
                            className="bg-red-500 text-white"
                        >
                            Hủy
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                changeStatus(data?.id, 'done');
                            }}
                            className="bg-primary text-white"
                        >
                            Duyệt
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    const onChange: PaginationProps['onChange'] = (page) => {
        console.log(page);
        setPage(page);
    };
    return (
        <main className="w-full flex flex-col gap-5">
            <section className="bg-white rounded-lg flex items-center justify-between py-5 px-5">
                <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                    <p>{PATHCONSTANTS.WITHDRAW_MONEY.TITLE}</p>
                </div>
                <div className="text-lg flex gap-5 duration-300">
                    {/* <Dropdown menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className="bg-gray-200 flex justify-center items-center h-[2.4rem] w-[2.4rem] rounded-lg">
                                <EllipsisOutlined
                                    style={{ fontSize: '1.5rem' }}
                                    className="translate-y-[0.15rem] text-gray-800"
                                />
                            </Space>
                        </a>
                    </Dropdown> */}
                    {/* <Link to={PATHCONSTANTS.USER_MANAGER_NEW.PATH}>
                      <Button type="primary" className="bg-primary h-[2.4rem] flex items-center">
                          <PlusCircleOutlined />
                          {PATHCONSTANTS.USER_MANAGER_NEW.TITLE}
                      </Button>
                  </Link> */}
                </div>
            </section>
            <section className="bg-white rounded-lg py-5 px-5">
                <div className="w-full flex items-center gap-5">
                    <Search
                        placeholder="Tìm kiếm"
                        enterButton="Tìm kiếm"
                        className="h-[2.75rem] w-full"
                        size="middle"
                    />
                </div>
                <Table loading={loading} columns={columns} dataSource={data} pagination={false} />
                <Pagination
                    className="flex justify-end pt-[10px]"
                    current={page}
                    onChange={onChange}
                    total={total * size}
                />
            </section>
        </main>
    );
};

export default WithdrawMoney;
