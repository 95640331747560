import { Flex, Spin } from 'antd';
import React from 'react';

export default function LoadAction() {
    return (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-[#ffffffce] flex justify-center items-center z-50">
            <Flex align="center" gap="middle">
                <Spin size="large" />
            </Flex>
        </div>
    );
}
