import { Button, Dropdown, Popconfirm, Select, Space, Table, TableColumnsType, TableProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHCONSTANTS } from '@/constants/PathConstants';
import http from '@/configs/http';
import { INews } from '@/types/News';
import { DeleteOutlined, EditFilled, EditOutlined, EllipsisOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { BlogType, ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import funcUtils from '@/configs/funcUtil';
type OnChange = NonNullable<TableProps<INews>['onChange']>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

function ListNews() {
    const abortController = useRef<AbortController | null>(null);
    const [dataFetch, setDataFetch] = useState<INews[]>();
    const [isLoading, setIsLoading] = useState(false);
    const getAllBlog = async () => {
        abortController.current?.abort();
        abortController.current = new AbortController();
        setIsLoading(true);
        try {
            const response = await http.get(`${ENDPOINTCONSTANTS.BLOGNEWS}`, {
                signal: abortController.current.signal,
            });
            setDataFetch(response.data);
            setIsLoading(false);
        } catch (error: any) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
                return;
            }
            setIsLoading(false);
            console.log(error);
        }
    };
    // SEARCH
    const [filteredData, setFilteredData] = useState<INews[]>([]);
    const handleSearch = (value: string) => {
        if (dataFetch) {
            const result = dataFetch.filter((item:any) => item?.title.includes(value));
            setFilteredData(result);
        }
    };
    // TABLE
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const onDelete = async (id: number) => {
        const rep = await http.delete(`${ENDPOINTCONSTANTS.BLOG}/${id}?blogType=${BlogType.BLOGNEWS}`);
        if (rep?.status === 200) {
            funcUtils.notify('Xóa Thành Công ', 'success');
            getAllBlog();
        }
    };
    // hide or show
    const [sortedInfo, setSortedInfo] = useState<Sorts>({});
    const [filteredInfo, setFilteredInfo] = useState<Filters>({});
    const handleChange: OnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter as Sorts);
    };
    const columnsProduct: TableColumnsType<INews> = [
        {
            title: '',
            dataIndex: 'image',
            key: 'id',
            render: (image, e: INews, i) => (
                <div key={e.id} className="flex gap-3 items-center">
                    <img src={image?.src} alt="avatar" style={{ width: '50px', height: '50px' }} />
                </div>
            ),
        },
        {
            title: 'Tin tức',
            dataIndex: 'title',
            key: 'id',
        },
        {
            title: 'Ngày Tạo',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => moment(date).format('DD/MM/YYYY'),
        },
        // {
        //     title: 'Trạng Thái',
        //     dataIndex: 'published',
        //     key: 'published',
        //     filters: [
        //         { text: 'Ẩn', value: false },
        //         { text: 'Hiện', value: true },
        //     ],
        //     filteredValue: filteredInfo.published || null,
        //     onFilter: (value: boolean | any, record: INews) => record.published === value,
        //     sorter: (a: INews, b: INews) => Number(a.published) - Number(b.published),
        //     sortOrder: sortedInfo.columnKey === 'published' ? sortedInfo.order : null,
        //     ellipsis: true,
        //     render: (published: boolean, e: INews, i) => (
        //         <p key={e.id} className={`${published === true ? 'text-primary' : 'text-red-400'}`}>
        //             {published === true ? 'Hiện' : 'Ẩn'}
        //         </p>
        //     ),
        // },
        {
            title: 'Thao Tác',
            dataIndex: 'id',
            key: 'action',
            render: (value, record, index) => {
                return (
                    <div key={index} className="flex gap-3 text-xl">
                        <Link to={`${PATHCONSTANTS.NEWS_EDIT.PATH.replace(':id', value)}`}>
                            <EditOutlined />
                        </Link>

                        <Popconfirm
                            title="Xóa bài viết"
                            description={`Bạn có chắc xóa bài viết có mã ${value}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => {
                                onDelete(value);
                            }}
                        >
                            <DeleteOutlined className="text-red-500" />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    useEffect(() => {
        getAllBlog();
    }, []);
    return (
        <>
            <main className="w-full flex flex-col gap-5">
                <section className="bg-white rounded-lg flex items-center justify-between py-5 px-5">
                    <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                        <p>{PATHCONSTANTS.NEWS.TITLE}</p>
                    </div>
                    <div className="text-lg flex gap-5 duration-300 items-center">
                        {/* <Dropdown  trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space className="bg-gray-200 flex justify-center items-center h-[2.4rem] w-[2.4rem] rounded-lg">
                                <EllipsisOutlined
                                    style={{ fontSize: '1.5rem' }}
                                    className="translate-y-[0.15rem] text-gray-800"
                                />
                            </Space>
                        </a>
                    </Dropdown> */}
                        <Link to={PATHCONSTANTS.NEWS_NEW.PATH}>
                            <Button type="primary" className="bg-primary h-[2.4rem] flex items-center">
                                <PlusCircleOutlined />
                                {PATHCONSTANTS.NEWS.TITLE}
                            </Button>
                        </Link>
                    </div>
                </section>

                <section className="bg-white rounded-lg py-5 px-5">
                    {/* <div className="w-full flex items-center gap-5">
                        <Search
                            placeholder="Tìm kiếm"
                            enterButton="Tìm kiếm"
                            className="h-[2.75rem] w-full"
                            size="middle"
                            onSearch={handleSearch}
                        />
                    </div> */}
                    <Table
                        rowSelection={rowSelection}
                        columns={columnsProduct}
                        dataSource={filteredData.length > 0 ? filteredData : dataFetch}
                        onChange={handleChange}
                        loading={isLoading}
                    />
                </section>
            </main>
        </>
    );
}

export default ListNews;
