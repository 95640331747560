import Loading from '@/components/shared/Loading';
import http from '@/configs/http';
import { ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { LocationAction } from '@/redux/reducers/location/locationReducer';
import { Countries, District, Province, Ward } from '@/types/Countries';
import { Button, Checkbox, Form, Input, Radio, RadioChangeEvent, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function UserNew() {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState<Countries[]>([]);
    const [provinces, setProvinces] = useState<Province[]>([]);
    const [districts, setDistricts] = useState<District[]>([]);
    const [wards, setWards] = useState<Ward[]>([]);

    const [isGender, setIsGender] = useState(0);
    const [isCountry, setIsCountry] = useState<Countries | null>(null);
    const [isProvince, setIsProvince] = useState<Province | null>(null);
    const [isDistrict, setIsDistrict] = useState<District | null>(null);
    const [isWard, setIsWard] = useState<Ward | null>(null);

    const naviagte = useNavigate();
    const dispatch = useAppDispatch();

    const abortController = useRef<AbortController | null>(null);

    const handleGetCountries = async () => {
        try {
            abortController.current?.abort();
            abortController.current = new AbortController();

            const response = await http.get(ENDPOINTCONSTANTS.COUNTRIES, {
                signal: abortController.current.signal,
            });

            const VN = response.data.countries.filter((country: Countries) => country.code === 'VN');

            handleGetProvinces(VN[0].id);

            setCountries(response.data.countries);
            setIsCountry(VN[0]);

            dispatch(LocationAction.updateCountry(response.data.countries));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleGetCountries();
    }, []);

    const handleGetProvinces = async (countryId: number) => {
        try {
            abortController.current?.abort();
            abortController.current = new AbortController();

            const response = await http.get(`${ENDPOINTCONSTANTS.COUNTRIES}/${countryId}/provinces`, {
                signal: abortController.current.signal,
            });

            setProvinces(response.data.provinces);

            dispatch(LocationAction.updateProvince(response.data.provinces));
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetDistricts = async (provinceId: number) => {
        try {
            abortController.current?.abort();
            abortController.current = new AbortController();

            const response = await http.get(`/information/provinces/${provinceId}/districts`, {
                signal: abortController.current.signal,
            });

            setDistricts(response.data.districts);

            dispatch(LocationAction.updateDistrict(response.data.districts));
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetWards = async (districtId: number) => {
        try {
            abortController.current?.abort();
            abortController.current = new AbortController();

            const response = await http.get(`/information/districts/${districtId}/wards`, {
                signal: abortController.current.signal,
            });

            setWards(response.data.wards);

            dispatch(LocationAction.updateWard(response.data.wards));
        } catch (error) {
            console.log(error);
        }
    };

    const hanldeCreateUser = async () => {
        setIsLoading(true);
        try {
            const data = {
                firstName: form.getFieldValue('first_name'),
                lastName: form.getFieldValue('last_name'),
                phone: form.getFieldValue('phoneNumber'),
                birthday: '2024-04-25',
                gender: isGender,
                role: form.getFieldValue('role'),
                addresses: [
                    {
                        country: isCountry?.code,
                        address1: form.getFieldValue('address'),
                        countryCode: isCountry?.code,
                        provinceCode: isProvince?.code,
                        districtCode: isDistrict?.code,
                        wardCode: isWard?.code,
                        zip: isDistrict?.code,
                    },
                ],
                note: form.getFieldValue('note'),
            };

            await http.post(ENDPOINTCONSTANTS.USER, data);

            toast.success('Tạo khách hàng thành công');
            naviagte('/manager/user');
        } catch (error) {
            console.log(error);
            toast.success('Lỗi khi tạo khách hàng');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <Loading />;

    return (
        <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full flex flex-col gap-5"
            autoComplete="off"
            onFinish={hanldeCreateUser}
        >
            <div>
                <h1 className="text-lg">Thông tin khách hàng</h1>
                <p className="font-semibold text-sm text-gray-400">
                    VUI LÒNG CUNG CẤP CÁC THÔNG TIN VỀ KHÁCH HÀNG SẼ TẠO
                </p>
            </div>
            <div className="w-full flex justify-end">
                <Button className="h-[2.75rem] bg-primary text-white font-semibold" htmlType="submit">
                    Thêm khách hàng
                </Button>
            </div>
            <div className="flex gap-10">
                <div className="flex-1 flex flex-col gap-10">
                    <div className="bg-white p-5 rounded-lg shadow-default">
                        <h1 className="font-semibold">Thông tin chung</h1>
                        <hr className="my-5" />
                        <div className="w-full grid grid-cols-2 gap-x-10">
                            <Form.Item
                                name="last_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập họ',
                                    },
                                ]}
                            >
                                <Input size="small" placeholder="Nhập họ" className="h-[2.75rem]" />
                            </Form.Item>
                            <Form.Item
                                name="first_name"
                                label="Tên"
                                rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                            >
                                <Input size="small" placeholder="Nhập tên" className="h-[2.75rem]" />
                            </Form.Item>
                            <Form.Item
                                name="phoneNumber"
                                label="Số điện thoại"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập số điện thoại' },
                                    {
                                        pattern: /^(09|08)\d{8}$/,
                                        message: 'Số điện thoại không đúng định dạng',
                                    },
                                ]}
                            >
                                <Input size="small" placeholder="Nhập số điện thoại" className="h-[2.75rem]" />
                            </Form.Item>
                            {/* <Form.Item
                                name="birthDay"
                                label="Chọn ngày sinh"
                                rules={[{ required: true, message: 'Vui lòng chọn ngày sinh' }]}
                            >
                                <Input
                                    size="small"
                                    placeholder="Nhập số điện thoại"
                                    type="date"
                                    className="h-[2.75rem]"
                                />
                            </Form.Item> */}
                        </div>
                        <div>
                            <Form.Item
                                label="Giới tính"
                                rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
                            >
                                <Radio.Group
                                    defaultValue={0}
                                    onChange={(e: RadioChangeEvent) => {
                                        setIsGender(e.target.value);
                                    }}
                                >
                                    <Radio value={0}> Nam </Radio>
                                    <Radio value={1}> Nữ </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name="advertisement" valuePropName="checked">
                                <Checkbox>Nhận email quảng cáo</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-default">
                        <h1 className="font-semibold">Địa chỉ</h1>
                        <hr className="my-5" />
                        <div className="w-full grid grid-cols-2 gap-x-10">
                            <Form.Item name="address" label="Địa chỉ" rules={[{ required: true }]}>
                                <Input size="small" placeholder="Nhập địa chỉ" className="h-[2.75rem]" />
                            </Form.Item>
                            <Form.Item label="Quốc gia" name={'country'}>
                                <Select
                                    className="h-[2.75rem]"
                                    placeholder="Chọn quốc gia"
                                    onChange={(value) => {
                                        handleGetProvinces(value);
                                        setIsCountry(countries.find((country) => country.id === value) || null);
                                        setIsProvince(null);
                                        setIsDistrict(null);
                                        setIsWard(null);
                                    }}
                                    disabled
                                    defaultValue={241}
                                >
                                    {countries.map((country) => (
                                        <Select.Option key={country.id} value={country.id}>
                                            {country.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Tỉnh/Thành phố"
                                name={'province'}
                                rules={[{ required: true, message: 'Vui lòng chọn tỉnh/ thành phố!' }]}
                            >
                                <Select
                                    placeholder="Chọn tỉnh / thành phố"
                                    className="h-[2.75rem]"
                                    onChange={(value) => {
                                        handleGetDistricts(value);
                                        setIsProvince(provinces.find((province) => province.id === value) || null);
                                    }}
                                >
                                    {provinces.map((province) => (
                                        <Select.Option key={province.id} value={province.id}>
                                            {province.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Quận/Huyện"
                                name={'district'}
                                rules={[{ required: true, message: 'Vui lòng chọn quận/ huyện!' }]}
                            >
                                <Select
                                    placeholder="Chọn quận/ huyện"
                                    className="h-[2.75rem]"
                                    onChange={(value) => {
                                        handleGetWards(value);
                                        setIsDistrict(districts.find((district) => district.id === value) || null);
                                    }}
                                >
                                    {districts.map((district) => (
                                        <Select.Option key={district.id} value={district.id}>
                                            {district.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Phường/ Xã"
                                name={'ward'}
                                rules={[{ required: true, message: 'Vui lòng chọn phường/ xã!' }]}
                            >
                                <Select
                                    placeholder="Chọn phường/ xã"
                                    className="h-[2.75rem]"
                                    onChange={(value) => {
                                        setIsWard(wards.find((ward) => ward.id === value) || null);
                                    }}
                                >
                                    {wards.map((ward) => (
                                        <Select.Option key={ward.id} value={ward.id}>
                                            {ward.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="flex-1 h-auto flex-col gap-10 flex">
                    <div className="bg-white p-5 rounded-lg h-auto w-full shadow-default">
                        <h1 className="font-semibold">Thông tin bổ sung</h1>
                        <hr className="my-5" />
                        <Form.Item name="note" label="Ghi chú" rules={[{ required: true }]}>
                            <Input size="small" placeholder="Nhập ghi chú" className="h-[2.75rem]" />
                        </Form.Item>
                    </div>
                    <div className="flex justify-start items-start">
                        <div className="bg-white p-5 rounded-lg h-auto w-full shadow-default">
                            <Form.Item name="exportInvoiceInformation" valuePropName="checked">
                                <Checkbox>Xuất thông tin hóa đơn</Checkbox>
                            </Form.Item>
                            <Form.Item
                                label="Loại khách hàng"
                                name={'role'}
                                rules={[{ required: true, message: 'Vui lòng chọn loại khách hàng!' }]}
                            >
                                <Select placeholder="Chọn loại khách hàng" className="h-[2.75rem]">
                                    <Select.Option value={'partnerA'}>Đối tác khách hàng hạng A</Select.Option>
                                    <Select.Option value={'partnerB'}>Đối tác khách hàng hạng B</Select.Option>
                                    <Select.Option value={'customer'}>Khách hàng thông thường</Select.Option>
                                    <Select.Option value={'staff'}>Nhân viên</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}
