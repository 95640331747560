export enum ECustomerRankNum {
    platinum = 4,
    gold = 3,
    silver = 2,
    staff = 1,
    none = 0,
}

export const EUserRole: Record<string, string> = {
    admin: 'Admin',
    partnerA: 'Partner A',
    partnerB: 'Partner B',
    customer: 'Khách hàng thông thường',
    staff: 'Nhân viên',
};

export const getCustomerRankName = (rankPoint: number): string => {
    switch (rankPoint) {
        case ECustomerRankNum.platinum:
            return 'Platinum';
        case ECustomerRankNum.gold:
            return 'Gold';
        case ECustomerRankNum.silver:
            return 'Silver';
        case ECustomerRankNum.staff:
            return 'Staff';
        case ECustomerRankNum.none:
            return 'Silver';
        default:
            return 'Silver';
    }
};

export const getCustomerRole = (role: string) => {
    return EUserRole[role];
};
