import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Form, FormProps, Input, Radio, Select, Upload, UploadFile } from 'antd';
import { useState } from 'react';
import { PATHCONSTANTS } from '@/constants/PathConstants';
import { INews } from '@/types/News';
import { BlogType, Category, ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import { UploadChangeParam } from 'antd/es/upload';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import funcUtils from '@/configs/funcUtil';
import http from '@/configs/http';
import { useNavigate } from 'react-router-dom';
import { Url } from 'url';
import { toast } from 'react-toastify';
const SMNEW = () => {
    const [downloadURL, setDownloadURL] = useState<any | Url>(null);
    const [fileListPDF, setFileListPDF] = useState<any | Url>(null);
    const [loading, setLoading] = useState(false);
    const [isEditorChanged, setIsEditorChanged] = useState(false);
    const [editorError, setEditorError] = useState('');
    const router = useNavigate();
    const form = Form.useForm();
    const storage = getStorage();
    const [htmlDescription, setHtmlDescription] = useState<string>('');
    const [SelectImg, setSelectImg] = useState<any>(null);
    function handleUpdload(even: UploadChangeParam<UploadFile<any>>) {
        if (even.fileList.length === 0) {
            setSelectImg(null);
        } else {
            if (SelectImg) {
                const imageRef = ref(storage, SelectImg);
                deleteObject(imageRef);
            }
            const fileupload = even?.fileList[0]?.originFileObj;
            const fileName = fileupload?.name;
            const image = ref(storage, `images/${fileName}-${crypto.randomUUID()}`);
            uploadBytes(image, fileupload as File).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((img) => {
                    return setSelectImg(img);
                });
            });
        }
    }
    const checkValiDes = () => {
        if (htmlDescription === undefined || htmlDescription?.length < 1) {
            setEditorError('Mô tả không được để trống!');
        } else {
            setEditorError('');
            setIsEditorChanged(true);
        }
    };
    const OnFinish: FormProps<INews>['onFinish'] = async (values: INews) => {
        setLoading(true);
        try {
            const payload = {
                title: values?.title,
                createdAt: values?.createdAt,
                bodyHtml: htmlDescription,
                blogId: BlogType.BLOGENDOW,
                author: null,
                publishedAt: values?.createdAt,
                published: values.published,
                summaryHtml: htmlDescription,
                post: {
                    pdfUrl: downloadURL,
                    userRole: 'admin',
                    discountAmount: 0,
                },
                image: { src: SelectImg ? SelectImg : values?.image?.src, attachment: 'test', filename: values.title },
            };
            if (isEditorChanged === true) {
                const response = await http.post(`${ENDPOINTCONSTANTS.BLOG}`, payload);
                if (response.status === 201) {
                    setLoading(false);
                    funcUtils.notify('Thêm Thành Công ', 'success');
                    router('/system-program');
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            funcUtils.notify('Thêm Thất Bại ', 'error');
            setLoading(false);
        }
    };
    const handleUploadPDF = async (even: UploadChangeParam<UploadFile<any>>) => {
        if (even.fileList.length === 0) {
            setFileListPDF([]);
        } else {
            if (fileListPDF?.length > 0) {
                // Use optional chaining here
                const pdfRef = ref(storage, downloadURL);
                deleteObject(pdfRef);
            }

            const fileupload = even?.fileList[0]?.originFileObj;
            const fileName = fileupload?.name;

            const pdf = ref(storage, `pdf/${fileName}-${crypto.randomUUID()}`);
            const toastId: any = funcUtils.notify('Đang upload file...', 'loading');
            try {
                await uploadBytes(pdf, fileupload as File).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((link) => {
                        setDownloadURL(link);
                        setFileListPDF(even.fileList);
                    });
                });
                toast.dismiss(toastId);
                funcUtils.notify('Upload file thành công!', 'success');
            } catch (error) {
                toast.dismiss(toastId);
                funcUtils.notify('Upload file thất bại!', 'error');
            }
        }
    };
    class UploadAdapter {
        loader: any;
        constructor(loader: any) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then(
                (file: File) =>
                    new Promise((resolve, reject) => {
                        const fileName = file.name;
                        const imageRef = ref(storage, `images/${fileName}-${crypto.randomUUID()}`);
                        uploadBytes(imageRef, file)
                            .then((snapshot) => {
                                getDownloadURL(snapshot.ref)
                                    .then((downloadURL) => {
                                        resolve({
                                            default: downloadURL,
                                        });
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }),
            );
        }

        abort() {
            // Handle abort
        }
    }

    const uploadPlugin = (editor: any) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return new UploadAdapter(loader);
        };
    };
    return (
        <Form
            form={form[0]}
            layout="vertical"
            autoComplete="off"
            onFinish={OnFinish}
            className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full"
        >
            <div className="col-span-3 bg-white rounded-lg flex justify-between gap-5 p-5">
                <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                    <p>{PATHCONSTANTS.SYSTEM_PROGRAM_NEW.TITLE}</p>
                </div>
                <div className="flex gap-5">
                    <Button
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                        className="w-full min-w-[100px]"
                        onClick={() => checkValiDes()}
                    >
                        Lưu
                    </Button>
                </div>
            </div>
            {/*  */}
            <section className="flex flex-col gap-5 p-5 col-span-3 bg-white rounded-lg xl:col-span-2 w-full">
                <div className="w-full flex-col gap-x-10">
                    <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-5">
                        <Form.Item
                            name={'title'}
                            label={'Tên hệ thống'}
                            rules={[{ required: true, message: 'Bạn chưa nhập tên hệ thống' }]}
                        >
                            <Input placeholder="Nhập Tên Hệ Thống" />
                        </Form.Item>
                        <Form.Item
                            name={'createAt'}
                            label={'Ngày Tạo '}
                            rules={[{ required: true, message: 'Bạn cần chọn ngày tạo' }]}
                        >
                            <Input type="date" />
                        </Form.Item>

                        {/* <Form.Item name={'price'} label={'Giá'}>
                            <Input className="" type="number" placeholder="Nhập Giá" />
                        </Form.Item> */}
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    {/* <label htmlFor="">Mô Tả</label> */}
                    <Form.Item label="Mô tả" rules={[{ required: true, message: 'Bạn cần chọn mô tả' }]}>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={uploadPlugin}
                            config={{
                                heading: {
                                    options: [
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                        {
                                            model: 'heading1',
                                            view: 'h1',
                                            title: 'Heading 1',
                                            class: 'ck-heading_heading1',
                                        },
                                        {
                                            model: 'heading2',
                                            view: 'h2',
                                            title: 'Heading 2',
                                            class: 'ck-heading_heading2',
                                        },
                                        {
                                            model: 'heading3',
                                            view: 'h3',
                                            title: 'Heading 3',
                                            class: 'ck-heading_heading3',
                                        },
                                        {
                                            model: 'heading4',
                                            view: 'h4',
                                            title: 'Heading 4',
                                            class: 'ck-heading_heading4',
                                        },
                                        {
                                            model: 'heading5',
                                            view: 'h5',
                                            title: 'Heading 5',
                                            class: 'ck-heading_heading5',
                                        },
                                        {
                                            model: 'heading6',
                                            view: 'h6',
                                            title: 'Heading 6',
                                            class: 'ck-heading_heading6',
                                        },
                                        {
                                            model: 'heading7',
                                            view: 'h7',
                                            title: 'Heading 7',
                                            class: 'ck-heading_heading7',
                                        },
                                    ],
                                },
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setHtmlDescription(data);
                            }}
                        />
                        {editorError && <p className="text-red-500">{editorError}</p>}
                    </Form.Item>
                </div>
            </section>

            <section className="col-span-3 xl:col-span-1 flex flex-col gap-5">
                <div className="p-5 bg-white rounded-lg">
                    <Form.Item label="Hình Ảnh" name={'image'} rules={[{ required: true, message: 'Chọn hình ảnh' }]}>
                        <Upload.Dragger
                            name="src"
                            maxCount={1}
                            beforeUpload={() => false}
                            onChange={(e) => handleUpdload(e)}
                        >
                            {SelectImg === null ? (
                                <>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </>
                            ) : (
                                <img src={SelectImg as string} alt={SelectImg as string} />
                            )}
                        </Upload.Dragger>
                    </Form.Item>
                </div>
                {/* <div className="p-5 bg-white rounded-lg">
                    <Form initialValues={{ published: true }}>
                        <Form.Item label="Ẩn/Hiện" name={'published'}>
                            <Radio.Group>
                                <Radio value={true}> Hiện</Radio>
                                <Radio value={false}> Ẩn </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </div> */}
            </section>
            <section className="flex flex-col gap-5 p-5 bg-white rounded-lg col-span-2 w-full">
                <Form.Item name={'pdfFile'} label={'Tệp PDF'}>
                    <Upload
                        maxCount={1}
                        accept=".pdf"
                        fileList={fileListPDF}
                        onChange={handleUploadPDF}
                        beforeUpload={() => false}
                        onRemove={() => {
                            setFileListPDF([]);
                            setDownloadURL(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Chọn tệp PDF</Button>
                    </Upload>
                </Form.Item>

                {downloadURL && <embed src={downloadURL} type="application/pdf" height="600px" width="100%" />}
            </section>
        </Form>
    );
};

export default SMNEW;
