import React, { useEffect, useState } from 'react';
import { CloudUploadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Input, Popconfirm, Radio, Upload } from 'antd';
import funcUtils, { GetParams } from '@/configs/funcUtil';
import { PATHCONSTANTS } from '@/constants/PathConstants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BlogType, ENDPOINTCONSTANTS } from '@/constants/EndPointConstants';
import http from '@/configs/http';
import { Url } from 'url';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { INews } from '@/types/News';
import moment from 'moment';
import { toast } from 'react-toastify';
const ProducUpdate = () => {
    const [downloadURL, setDownloadURL] = useState<any | Url>(null);
    const [fileListPDF, setFileListPDF] = useState<any | Url>(null);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditorChanged, setIsEditorChanged] = useState(false);
    const [editorError, setEditorError] = useState('');
    const router = useNavigate();
    const [htmlDescription, setHtmlDescription] = useState<any>();
    const [SelectImg, setSelectImg] = useState<any | Url>(null);
    const [dataNews, setDataNews] = useState<INews>();
    const [form] = Form.useForm();
    const { id } = useParams();
    const storage = getStorage();
    function handleUpdload(even: UploadChangeParam<UploadFile<any>>) {
        if (even.fileList.length === 0) {
            setSelectImg(null);
        } else {
            if (SelectImg) {
                const imageRef = ref(storage, SelectImg);
                deleteObject(imageRef);
            }
            const fileupload = even?.fileList[0]?.originFileObj;
            const fileName = fileupload?.name;
            const image = ref(storage, `images/${fileName}-${crypto.randomUUID()}`);
            uploadBytes(image, fileupload as File).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((img) => {
                    return setSelectImg(img);
                });
            });
        }
    }
    const fecthData = async () => {
        const rep = await http.get(`${ENDPOINTCONSTANTS.BLOG}/${id}?blogType=${BlogType.BLOGOSPRODUCT}`);
        if (rep?.status === 200 && rep?.data) {
            setDataNews(rep?.data);
        }
    };

    useEffect(() => {
        if (dataNews) {
            form.setFieldsValue({
                title: dataNews.title,
                createAt: dataNews.createdAt,
                image: dataNews.image,
                published: dataNews.published,
                url: dataNews.url,
            });
        }
    }, [dataNews, form]);
    useEffect(() => {
        fecthData();
    }, []);
    const onDelete = async () => {
        setLoadingRemove(true);
        const rep = await http.delete(`${ENDPOINTCONSTANTS.BLOG}/${id}?blogType=${BlogType.BLOGOSPRODUCT}`);
        if (rep?.status === 200) {
            setLoadingRemove(false);
            funcUtils.notify('Xóa Thành Công ', 'success');
            router('/product-osd');
        }
    };
    // const checkValiDes = () => {
    //     if (dataNews?.bodyHtml === '' && htmlDescription === undefined) {
    //         setEditorError('Mô tả không được để trống!');
    //     } else {
    //         setEditorError('');
    //         setIsEditorChanged(true);
    //     }
    // };
    const OnFinish: FormProps<INews>['onFinish'] = async (values: INews) => {
        setLoading(true);
        try {
            const payload = {
                url: values?.url,
                title: values?.title,
                createdAt: values?.createdAt,
                bodyHtml: htmlDescription,
                blogId: BlogType.BLOGOSPRODUCT,
                publishedAt: values?.createdAt,
                published: values.published,
                summaryHtml: htmlDescription,
                post: {
                    pdfUrl: downloadURL === null ? dataNews?.post?.pdfUrl : downloadURL,
                    userRole: null,
                    discountAmount: 0,
                },
                image: { src: SelectImg ? SelectImg : values?.image?.src, attachment: 'test', filename: values.title },
                metaDescription: 'META HELLO OPPOST',
            };
            setLoading(false);
            const response = await http.patch(`${ENDPOINTCONSTANTS.BLOG}/${id}`, payload);
            if (response.status === 200) {
                fecthData();
                funcUtils.notify('Sửa Thành Công ', 'success');
                // router('/product-osd');
            }
        } catch (e) {
            setLoading(false);
            funcUtils.notify('Sửa Thất Bại ', 'error');
        }
    };
    const handleDeletePDF = async () => {
        if (dataNews?.post?.pdfUrl) {
            try {
                const payload = {
                    ...dataNews,
                    post: {
                        pdfUrl: null,
                        userRole: null,
                        discountAmount: 0,
                    },
                };
                const response = await http.patch(`${ENDPOINTCONSTANTS.BLOG}/${id}`, payload);
                const pdfRef = ref(storage, dataNews?.post?.pdfUrl);
                deleteObject(pdfRef).then(() => {
                    setDownloadURL('');
                    setFileListPDF([]);
                });
                if (response.status === 200) {
                    funcUtils.notify('Xóa thành công ', 'success');
                    fecthData();
                    // router('/system-program');
                }
            } catch (error) {
                funcUtils.notify('Xóa Thất Bại ', 'error');
            }
        }
    };
    const handleDeleteIMG = async () => {
        if (dataNews?.image?.src) {
            try {
                const payload = {
                    ...dataNews,
                    image: {
                        src: null,
                        attachment: null,
                        filename: null,
                        createdAt: null,
                    },
                };
                const response = await http.patch(`${ENDPOINTCONSTANTS.BLOG}/${id}`, payload);
                const pdfRef = ref(storage, dataNews?.image?.src);
                deleteObject(pdfRef).then(() => {
                    setSelectImg('');
                });
                if (response.status === 200) {
                    funcUtils.notify('Xóa thành công ', 'success');
                    fecthData();
                    // router('/system-program');
                }
            } catch (error) {
                funcUtils.notify('Xóa Thất Bại ', 'error');
            }
        }
    };
    const handleUploadPDF = async (even: UploadChangeParam<UploadFile<any>>) => {
        if (even.fileList.length === 0) {
            setFileListPDF([]);
        } else {
            if (fileListPDF?.length > 0) {
                // Use optional chaining here
                const pdfRef = ref(storage, downloadURL);
                deleteObject(pdfRef);
            }

            const fileupload = even?.fileList[0]?.originFileObj;
            const fileName = fileupload?.name;

            const pdf = ref(storage, `pdf/${fileName}-${crypto.randomUUID()}`);

            const toastId: any = funcUtils.notify('Đang upload file...', 'loading');
            try {
                await uploadBytes(pdf, fileupload as File).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((link) => {
                        setDownloadURL(link);
                        setFileListPDF(even.fileList);
                    });
                });
                toast.dismiss(toastId);
                funcUtils.notify('Upload file thành công!', 'success');
            } catch (error) {
                toast.dismiss(toastId);
                funcUtils.notify('Upload file thất bại!', 'error');
            }
        }
    };
    class UploadAdapter {
        loader: any;
        constructor(loader: any) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then(
                (file: File) =>
                    new Promise((resolve, reject) => {
                        const fileName = file.name;
                        const imageRef = ref(storage, `images/${fileName}-${crypto.randomUUID()}`);
                        uploadBytes(imageRef, file)
                            .then((snapshot) => {
                                getDownloadURL(snapshot.ref)
                                    .then((downloadURL) => {
                                        resolve({
                                            default: downloadURL,
                                        });
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }),
            );
        }

        abort() {
            // Handle abort
        }
    }

    const uploadPlugin = (editor: any) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return new UploadAdapter(loader);
        };
    };
    return (
        <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full flex flex-col gap-5"
            autoComplete="off"
            onFinish={OnFinish}
        >
            <div className="col-span-3 bg-white rounded-lg flex justify-between gap-5 p-5">
                <div className="text-xl font-semibold flex flex-1 items-center gap-5">
                    <p>{PATHCONSTANTS.PRODUCT_OSD_EDIT.TITLE}</p>
                </div>
                <div className="flex gap-5">
                    <div className="flex gap-3">
                        {' '}
                        {/* <Popconfirm
                            title="Xóa bài viết"
                            description={`Bạn có chắc xóa bài viết có mã ${id}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={onDelete}
                        >
                            <Button loading={loadingRemove} danger>
                                Xóa Sản Phẩm
                            </Button>
                        </Popconfirm> */}
                        <Button
                            loading={loading}
                            htmlType="submit"
                            type="primary"
                            className="w-full max-w-[200px]"
                            // onClick={() => {
                            //     checkValiDes();
                            // }}
                        >
                            Lưu
                        </Button>
                    </div>
                </div>
            </div>
            <section className="flex flex-col gap-5 p-5 col-span-3 bg-white rounded-lg xl:col-span-2 w-full">
                <div className="w-full grid grid-cols-2 gap-x-10">
                    <Form.Item name={'url'} label={'Url'}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'title'} label={'Tiêu đề'}>
                        <Input />
                    </Form.Item>

                    {/* <Form.Item name={'title'} label={'Tiêu Đề '} initialValue={dataNews?.title}>
                        <Input defaultValue={dataNews?.title} />
                    </Form.Item>

                    <Form.Item
                        name={'createdAt'}
                        label={'Ngày Tạo '}
                        initialValue={moment(dataNews?.createdAt).format('YYYY-MM-DD')}
                    >
                        <Input type="date" defaultValue={moment(dataNews?.createdAt).format('YYYY-MM-DD')} />
                    </Form.Item> */}
                    {/* <Form.Item name={'price'} label={'Giá'}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name={'category'} label={'Danh mục'}>
                        <Input type="text" />
                    </Form.Item> */}
                </div>
                <div className="">
                    <label htmlFor="">Mô Tả</label>
                    {dataNews && (
                        <CKEditor
                            editor={ClassicEditor}
                            data={dataNews.bodyHtml}
                            onReady={uploadPlugin}
                            config={{
                                heading: {
                                    options: [
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                        {
                                            model: 'heading1',
                                            view: 'h1',
                                            title: 'Heading 1',
                                            class: 'ck-heading_heading1',
                                        },
                                        {
                                            model: 'heading2',
                                            view: 'h2',
                                            title: 'Heading 2',
                                            class: 'ck-heading_heading2',
                                        },
                                        {
                                            model: 'heading3',
                                            view: 'h3',
                                            title: 'Heading 3',
                                            class: 'ck-heading_heading3',
                                        },
                                        {
                                            model: 'heading4',
                                            view: 'h4',
                                            title: 'Heading 4',
                                            class: 'ck-heading_heading4',
                                        },
                                        {
                                            model: 'heading5',
                                            view: 'h5',
                                            title: 'Heading 5',
                                            class: 'ck-heading_heading5',
                                        },
                                        {
                                            model: 'heading6',
                                            view: 'h6',
                                            title: 'Heading 6',
                                            class: 'ck-heading_heading6',
                                        },
                                        {
                                            model: 'heading7',
                                            view: 'h7',
                                            title: 'Heading 7',
                                            class: 'ck-heading_heading7',
                                        },
                                    ],
                                },
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setHtmlDescription(data);
                            }}
                        />
                    )}
                    {editorError && <p className="text-red-500">{editorError}</p>}
                </div>
            </section>
            <section className="flex flex-col  gap-5 col-span-3 xl:col-span-1  ">
                <div className="p-5 bg-white rounded-lg">
                    {' '}
                    <Form.Item label="Trạng Thái" name={'published'} initialValue={dataNews?.published}>
                        <Radio.Group defaultValue={dataNews?.published}>
                            <Radio value={true} checked={dataNews?.published === true}>
                                {' '}
                                Bật{' '}
                            </Radio>
                            <Radio value={false} checked={dataNews?.published === false}>
                                {' '}
                                Tắt{' '}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="p-5 bg-white rounded-lg flex gap-[20px] md:flex-row flex-col relative ">
                    <Form.Item label="Hình Ảnh" name={'image'} className="w-full mt-[5px]">
                        <Upload.Dragger
                            name="src"
                            maxCount={1}
                            beforeUpload={() => false}
                            onChange={(e) => handleUpdload(e)}
                        >
                            {SelectImg ? (
                                <img src={SelectImg as string} alt={SelectImg as string} className="w-full" />
                            ) : dataNews?.image?.src ? (
                                <img src={dataNews.image.src} alt={dataNews.image.src} className="w-full" />
                            ) : (
                                <>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </>
                            )}
                        </Upload.Dragger>
                    </Form.Item>
                    {dataNews?.image?.src && (
                        <Button danger className="ml-[90px] absolute " onClick={() => handleDeleteIMG()}>
                            Xóa hình
                        </Button>
                    )}
                    <div className="flex w-full flex-col">
                        <Form.Item name={'pdfUrl'} label={'Tệp PDF'}>
                            <div className="flex flex-row">
                                <Upload
                                    maxCount={1}
                                    accept=".pdf"
                                    fileList={fileListPDF}
                                    onChange={handleUploadPDF}
                                    beforeUpload={() => false}
                                    onRemove={() => {
                                        setFileListPDF([]);
                                        setDownloadURL(null);
                                    }}
                                >
                                    <Button icon={<CloudUploadOutlined />}>Chọn tệp PDF</Button>
                                </Upload>
                                {dataNews?.post?.pdfUrl && (
                                    <Button className="ml-[10px]" onClick={() => handleDeletePDF()}>
                                        Xóa file PDF
                                    </Button>
                                )}
                            </div>
                        </Form.Item>

                        <embed
                            src={downloadURL === null ? dataNews?.post?.pdfUrl : downloadURL}
                            type="application/pdf"
                            width="100%"
                            height={'600vh'}
                        />
                    </div>
                </div>
            </section>
        </Form>
    );
};

export default ProducUpdate;
