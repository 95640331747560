import Loading from '@/components/shared/Loading';
import http from '@/configs/http';
import { DetailsUser } from '@/types/Auth';
import { ItemCoupon } from '@/types/Coupon';
import { Button, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import ItemInvitecode from '../ItemInviteCode';

type InviteCodeProps = {
    user?: DetailsUser;
};
export default function InviteCode(user: InviteCodeProps) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listCoupon, setListCoupon] = React.useState<ItemCoupon[]>([]);

    const [isShowOpenModal, setIsShowOpenModal] = React.useState<boolean>(false);
    const [isValueNote, setIsValueNote] = React.useState<string>('');

    const handleCreateInviteCode = async () => {
        setIsLoading(true);
        try {
            const data = {
                ownerId: user.user?.id,
                type: 'partner',
                role: 'partnerB',
                note: isValueNote,
            };
            await http.post('/coupon-ref', data);
            toast.success('Tạo mã mời thành công');
        } catch (error) {
            console.log(error);
            toast.error('Tạo mã mời thất bại');
        } finally {
            setIsLoading(false);
            handleGetInviteCode();
            setIsShowOpenModal(false);
            setIsValueNote('');
        }
    };

    const handleGetInviteCode = async () => {
        setIsLoading(true);
        try {
            const res = await http.get(`/coupon-ref/invite/${user.user?.id}`, {
                params: {
                    type: 'partner',
                },
            });
            setListCoupon(res.data.items);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetInviteCode();
    }, []);

    if (user.user?.role !== 'partnerA') return null;

    return (
        <div className="bg-white p-5 rounded-lg h-auto w-full shadow-default">
            <Modal
                open={isShowOpenModal}
                onOk={() => {
                    if (isValueNote === '') {
                        toast.error('Ghi chú không được để trống');
                        return;
                    } else {
                        handleCreateInviteCode();
                    }
                }}
                onCancel={() => setIsShowOpenModal(false)}
                title="Tạo mã mời"
            >
                <div className="py-4 flex flex-col gap-4">
                    <label htmlFor="">Ghi chú:</label>
                    <Input placeholder="adas" value={isValueNote} onChange={(e) => setIsValueNote(e.target.value)} />
                </div>
            </Modal>
            <div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="w-full flex flex-col  gap-5 overflow-y-scroll hidden-scrollbar">
                            {listCoupon && listCoupon.length > 0 ? (
                                listCoupon.map((item, index) => (
                                    <ItemInvitecode key={index} {...item} getListCoupon={() => handleGetInviteCode()} />
                                ))
                            ) : (
                                <div className="flex justify-center items-center">
                                    <p className="text-blue-500 mt-8 underline">Không có mã mời nào</p>
                                </div>
                            )}
                        </div>
                        <div className="w-full justify-end mt-3">
                            <Button type="primary" onClick={() => setIsShowOpenModal(true)}>
                                Tạo mã mời
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
